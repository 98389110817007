import * as API_ENDPOINTS from './apiEndpoints';
import { callApi, getUserConfig } from '../utils/utils';
import { SET_ALL_CONVERSATIONS, SET_CONVERSATION_ENGAGED_LIST, SET_ERRORS_LIST, SET_FILTERS, SET_MERCHANTS_LIST, SET_USERS_LIST, SET_SELECTED_VALUE,SET_SELECTED_TIMEZONE, SET_TOPIC_ANALYSIS_VISUALIZATION, SET_TOPIC_TREND_ANALYSIS_VISUALIZATION, SET_TOPIC_FLOW_ANALYSIS_VISUALIZATION, GET_CUSTOMER_CONFIG, SET_LOADING_LIST } from './actionTypes';
//  sample action

export const getAllConversations = (object) => {
    return async (dispatch) => {
        try {
            const apiResults = await callApi(API_ENDPOINTS.GET_ALL_CONVERSATIONS, 'POST', object);
         
            dispatch({
                type: SET_ALL_CONVERSATIONS, payload: {
                    data: {
                        result: apiResults,
                    },
                    status: "SUCCESS",
                }
            });
        } catch (error) {
            dispatch({
                type: SET_ALL_CONVERSATIONS, payload: {
                    status: "ERROR",
                }
            });
        }

    }
}

export const getConversationEngagedList = (page, object) => {
    return async (dispatch) => {
        try {
            const apiResults = await callApi(API_ENDPOINTS.CONVERSATION_ENGAGED_LIST, 'POST', object);
            await dispatch({
                type: SET_CONVERSATION_ENGAGED_LIST, payload: {
                    data: {
                        result: apiResults,
                        page,
                    },
                    status: "SUCCESS",
                }
            });
        } catch (err) {
            await dispatch({
                type: SET_CONVERSATION_ENGAGED_LIST, payload: {
                    status: "ERROR",
                }
            });
        }
    }
}


export const getAllMerchantsErrorsReport = (object) => {
    return async (dispatch) => {
        try {
            const apiResults = await callApi(API_ENDPOINTS.ERRORS_LIST, 'POST', object);
            dispatch({
                type: SET_ERRORS_LIST, payload: {
                    data: {
                        result: apiResults,
                    },
                    status: "SUCCESS",
                }
            });
        } catch (error) {
            dispatch({
                type: SET_ERRORS_LIST, payload: {
                    status: "ERROR",
                }
            });
        }

    }
}

export const getAllMerchants = (object) => {
    return async (dispatch) => {
        try {
            const apiResults = await callApi(API_ENDPOINTS.MERCHANTS_LIST, 'POST', object);
            dispatch({
                type: SET_MERCHANTS_LIST, payload: {
                    data: {
                        result: apiResults
                    },
                    status: "SUCCESS"
                }
            });
        } catch (error) {
            dispatch({
                type: SET_MERCHANTS_LIST, payload: {
                    status: "ERROR"
                }
            });
        }

    }
}

export const getAllUsers = (object) => {
    return async (dispatch) => {
        try {
            const apiResults = await callApi(API_ENDPOINTS.USERS_LIST, 'POST', object);
            dispatch({
                type: SET_USERS_LIST, payload: {
                    data: {
                        result: apiResults
                    },
                    status: "SUCCESS"
                }
            });
        } catch (error) {
            dispatch({
                type: SET_USERS_LIST, payload: {
                    status: "ERROR"
                }
            });
        }

    }
}

export const getTopics = (filters) => {
    return async (dispatch) => {
        try {

            let date = new Date(filters.startDate);
            const startDate = date.toLocaleDateString('en-US')
            date = new Date(filters.endDate);
            const endDate = date.toLocaleDateString('en-US')
            const object = {
                'startDate': startDate + ' ' + filters.startTime,
                'endDate': endDate + ' ' + filters.endTime,
                // 'page_no': page_no != ''? page_no:page,
                'landingpage': filters.landingpage,
                'sort': filters.sort,
                'sorting': filters.sorting,
                'reporttype': filters.reporttype,
                'conversation_id': filters.conversationId,
                'su_id': filters.suId,
                'apptDate': filters.apptDate,
                'turn_id':filters.turnID,
                'convT2A':filters.convT2A,
                'utmParam':filters.utmParam,
                'convOutcome':filters.convOutcome,
                'MarketoLead':filters.MarketoLead,
                'chatRating':filters.chatRating,
                'chatFeedback':filters.chatFeedback,
                'utm_source':filters.utm_source,
                'utm_medium':filters.utm_medium,
                'utm_campaign':filters.utm_campaign,
                'utm_placement':filters.utm_placement,
                'utm_term':filters.utm_term,
                'utm_content':filters.utm_content,
                'utm_adplacement':filters.utm_adplacement,
                'utm_match':filters.utm_match,
                'utm_location':filters.utm_location,
                'utm_target':filters.utm_target,  
                'gclid':filters.gclid,
                'device_type':filters.device_type,
                'merchant':filters.merchant,
                "timezone":filters.timezone,
                'topics': filters.topic,
                'turn_rating': filters.turn_rating,
                'turn_feedback': filters.turn_feedback
            }
            const apiResults = await callApi(API_ENDPOINTS.TOPIC_VISUALIZATION, 'POST', {
                ...object,
                'visualization_type': "TOPICS"
            });

            dispatch({
                type: SET_TOPIC_ANALYSIS_VISUALIZATION, 
                payload: {
                    data: apiResults,
                    status: "SUCCESS",
                }
            
                    // data: {
                    //     "turn_count": 1693,
                    //     "grouped_topics": [
                    //         {
                    //             "id": 4,
                    //             "name": "Payment Methods",
                    //             "color_code": "rgb(144, 12, 63)",
                    //             "count": 3
                    //         },
                    //         {
                    //             "id": 6,
                    //             "name": "Business Type",
                    //             "color_code": "rgb(88, 24, 69)",
                    //             "count": 5
                    //         },
                    //         {
                    //             "id": 3,
                    //             "name": "Pricing",
                    //             "color_code": "rgb(199, 0, 57)",
                    //             "count": 8
                    //         },
                    //         {
                    //             "id": 2,
                    //             "name": "Customer Support",
                    //             "color_code": "rgb(255, 195, 0)",
                    //             "count": 2
                    //         },
                    //         {
                    //             "id": 1,
                    //             "name": "Scheduling",
                    //             "color_code": "rgb(255, 87, 51)",
                    //             "count": 4
                    //         },
                    //         {
                    //             "id": 20,
                    //             "name": "Greetings",
                    //             "color_code": "rgb(0, 191, 255)",
                    //             "count": 1
                    //         },
                    //         {
                    //             "id": 21,
                    //             "name": "Product Inquiry",
                    //             "color_code": "rgb(199, 0, 57)",
                    //             "count": 2
                    //         },
                    //         {
                    //             "id": 22,
                    //             "name": "Confirmation",
                    //             "color_code": "rgb(223, 255, 0)",
                    //             "count": 1
                    //         },
                    //         {
                    //             "id": 23,
                    //             "name": "Product Usage",
                    //             "color_code": "rgb(255, 160, 122)",
                    //             "count": 1
                    //         }],
                    //     },
                    // status: "SUCCESS",
                // },
                    // payload: {//             {
                //                 "id": 24,
                //                 "name": "Other",
                //                 "color_code": "rgb(163, 203, 56)",
                //                 "count": 1
                //             },
                //             {
                //                 "id": 25,
                //                 "name": "Postal Code",
                //                 "color_code": "rgb(139, 0, 0)",
                //                 "count": 1
                //             },
                //             {
                //                 "id": 26,
                //                 "name": "Contact Information",
                //                 "color_code": "rgb(255, 215, 0)",
                //                 "count": 1
                //             },
                //             {
                //                 "id": 17,
                //                 "name": "Business Operations",
                //                 "color_code": "rgb(253, 167, 223)",
                //                 "count": 1
                //             },
                //             {
                //                 "id": 18,
                //                 "name": "Hardware Features",
                //                 "color_code": "rgb(253, 203, 110)",
                //                 "count": 1
                //             },
                //             {
                //                 "id": 19,
                //                 "name": "Software Features",
                //                 "color_code": "rgb(111, 30, 81)",
                //                 "count": 1
                //             },
                //             {
                //                 "id": 7,
                //                 "name": "POS Features",
                //                 "color_code": "rgb(85, 230, 193)",
                //                 "count": 3
                //             },
                //             {
                //                 "id": 14,
                //                 "name": "Inventory Management",
                //                 "color_code": "rgb(0, 98, 102)",
                //                 "count": 1
                //             },
                //             {
                //                 "id": 13,
                //                 "name": "POS Hardware",
                //                 "color_code": "rgb(234, 32, 39)",
                //                 "count": 1
                //             },
                //             {
                //                 "id": 15,
                //                 "name": "Special Offers",
                //                 "color_code": "rgb(6, 82, 221)",
                //                 "count": 1
                //             },
                //             {
                //                 "id": 16,
                //                 "name": "POS Purchase",
                //                 "color_code": "rgb(217, 128, 250)",
                //                 "count": 1
                //             },
                //             {
                //                 "id": 8,
                //                 "name": "Purchasing Process",
                //                 "color_code": "rgb(154, 18, 179)",
                //                 "count": 2
                //             },
                //             {
                //                 "id": 9,
                //                 "name": "Product Information",
                //                 "color_code": "rgb(52, 152, 219)",
                //                 "count": 4
                //             },
                //             {
                //                 "id": 10,
                //                 "name": "Product Comparison",
                //                 "color_code": "rgb(125, 206, 160)",
                //                 "count": 1
                //             },
                //             {
                //                 "id": 11,
                //                 "name": "Sales Inquiry",
                //                 "color_code": "rgb(253, 114, 114)",
                //                 "count": 1
                //             },
                //             {
                //                 "id": 12,
                //                 "name": "User Information",
                //                 "color_code": "rgb(246, 229, 141)",
                //                 "count": 1
                //             }
                //         ],
                //     }
                // },
                
            });
        } catch (error) {
            //console.log(error);
            dispatch({
                type: SET_USERS_LIST, payload: {
                    status: "ERROR"
                }
            });
        }
    }


    

    
}

export const getTopicsTrendAnalysisVisualizationData = (
    filters,
) => {
    return async (dispatch) => {
        try {
            const apiResults = await callApi(API_ENDPOINTS.TOPIC_VISUALIZATION, 'POST', {
                ...filters,
                visualization_type: 'TOPICS_TREND'
            });
            dispatch({
                type: SET_TOPIC_TREND_ANALYSIS_VISUALIZATION,
                payload: {
                    data: apiResults,
                    status: "SUCCESS",
                }
            });
        } catch (error) {
            dispatch({
                type: SET_TOPIC_TREND_ANALYSIS_VISUALIZATION, payload: {
                    status: "ERROR"
                }
            });
        }

    }
}

export const getTopicsFlowAnalysisVisualizationData = (filters) => {
            return async (dispatch) => {
                try {
        
                    let date = new Date(filters.startDate);
                    const startDate = date.toLocaleDateString('en-US')
                    date = new Date(filters.endDate);
                    const endDate = date.toLocaleDateString('en-US')
                    const object = {
                        'startDate': startDate + ' ' + filters.startTime,
                        'endDate': endDate + ' ' + filters.endTime,
                        // 'page_no': page_no != ''? page_no:page,
                        'landingpage': filters.landingpage,
                        'sort': filters.sort,
                        'sorting': filters.sorting,
                        'reporttype': filters.reporttype,
                        'conversation_id': filters.conversationId,
                        'su_id': filters.suId,
                        'apptDate': filters.apptDate,
                        'turn_id':filters.turnID,
                        'convT2A':filters.convT2A,
                        'utmParam':filters.utmParam,
                        'convOutcome':filters.convOutcome,
                        'MarketoLead':filters.MarketoLead,
                        'chatRating':filters.chatRating,
                        'chatFeedback':filters.chatFeedback,
                        'utm_source':filters.utm_source,
                        'utm_medium':filters.utm_medium,
                        'utm_campaign':filters.utm_campaign,
                        'utm_placement':filters.utm_placement,
                        'utm_term':filters.utm_term,
                        'utm_content':filters.utm_content,
                        'utm_adplacement':filters.utm_adplacement,
                        'utm_match':filters.utm_match,
                        'utm_location':filters.utm_location,
                        'utm_target':filters.utm_target,  
                        'gclid':filters.gclid,
                        'device_type':filters.device_type,
                        'merchant':filters.merchant,
                        "timezone":filters.timezone,
                        'topics': filters.topic,
                        'turn_rating': filters.turn_rating,
                        'turn_feedback': filters.turn_feedback,
                        'origin': filters.origin,
                    }

                    const apiResults = await callApi(API_ENDPOINTS.TOPIC_VISUALIZATION, 'POST', {
                        ...object,
                        'visualization_type': "TOPIC_FLOW"
                    });
                    dispatch({
                        type: SET_TOPIC_FLOW_ANALYSIS_VISUALIZATION, 
                        payload: {
                            data: apiResults,
                            status: 'SUCCESS',
                        }
                    });
        } catch (error) {
            //console.log("error")
        }

    }
}

export const setFilters = (updatedFilters) => ({type: SET_FILTERS, payload: updatedFilters})

export const setSelectedValue = (value) => ({type: SET_SELECTED_VALUE, payload: value})

export const setLoadingList = (value) => ({type: SET_LOADING_LIST, payload: value})

export const setSelectedTimezone = (value) => ({type: SET_SELECTED_TIMEZONE, payload: value})

export const getCustomerConfig = (selectedCustomerObj) => {
    return async (dispatch) => {

        try {

            const config_values = await getUserConfig(selectedCustomerObj);
            dispatch({
                type: GET_CUSTOMER_CONFIG, payload: {
                    data: config_values,
                    status: "SUCCESS",
                }
            });
        } catch (error) {
            dispatch({
                type: GET_CUSTOMER_CONFIG, payload: {
                    status: "ERROR",
                }
            });
        }

    }
}
