import React from "react";
import LineChart from "./LineChart";
function MetricsDashboard() {
  return (
    <>
      <LineChart />
    </>
  );
}

export default MetricsDashboard;
