import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routes from "routes.js";

const Sidebar = ({ open, onClose }) => {
  return (
    <div
      className={`sm:none duration-175 linear fixed 
        // Position it right below the header (50px) and make it fill the remaining height
        inset-x-0 top-[50px] bottom-0
        // Base styles
        bg-secondaryColor w-64 
        // Z-index and overflow handling
        !z-50 overflow-y-auto
        // Background and theme colors
        bg-white dark:!bg-navy-800 dark:text-white
        // Shadow and transition
        shadow-2xl shadow-white/5 transition-all
        // Responsive z-index
        md:!z-50 lg:!z-50
        // Translation for showing/hiding
        ${open ? "translate-x-0" : "-translate-x-96"}`}
    >
      <div className="flex flex-col h-full">
        {/* Close button */}
        <button
          className="absolute top-4 right-4 block cursor-pointer xl:hidden text-white z-[90] p-2 hover:bg-white/10 rounded-lg"
          onClick={onClose}
          aria-label="Close sidebar"
        >
          <HiX className="h-5 w-5" />
        </button>

        {/* Main content wrapper */}
        <div className="flex flex-col flex-1">
          {/* Navigation links */}
          <nav className="flex-1">
            <Links routes={routes} />
          </nav>

          {/* Footer */}
          <footer className="p-4 mt-auto">
            <p className="font-poppins text-white text-sm font-medium">
              © {new Date().getFullYear()} All Rights Reserved
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;