import { useEffect, useRef, useState, useMemo } from "react";
import Dropdown from "react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import Select from "react-select";
import { components } from "react-select";

import { setFilters } from "store/actions";
import { getTopics } from "store/actions";
import Filters from "../../../components/filters";
import BubbleChart from "components/charts/BubbleChart";
import {
  SET_TOPIC_SELECTION_FOR_VISUALIZATION,
  SET_TOPIC_SELECTED_FOR_VISUALIZATION,
} from "../../../store/actionTypes";
import BarChart from "components/charts/BarChart";
import {
  getUserConfig,
  generateUniqueColors,
  addToolTipDiv,
  addTooltipEvent,
  getTopicDescription,
} from "utils/utils";
import toGetColorCode from "hooks/useToGetColorCode";
import NoResultFound from "components/noResultFound/NoResultFound";
import { AiOutlinePlus } from "react-icons/ai";
var tinycolor = require("tinycolor2");

function getDistinctTopics(topicsData) {
  const { grouped_topics = [] } = topicsData;

  if (!grouped_topics) {
    return [];
  }

  const topicSelections =
    grouped_topics &&
    grouped_topics.map((grouped_topic) => {
      return {
        id: grouped_topic.id,
        value: grouped_topic.id,
        label: grouped_topic.name,
      };
    });

  return topicSelections;
}

function getNameToDisplay(count, name) {
  return (count * 4).toFixed(1) > 5 ? name.split(" ").join("\n") : name;
}

function transformTopicsDataToChartFormat(
  topicsData,
  topicsSelectedForVisualization,
  showDefault,
  unique_merchantColors
) {
  const chartsData = [];
  const { grouped_topics } = topicsData;

  if (!grouped_topics) {
    return chartsData;
  }

  var leng = 0;

  for (const topic of grouped_topics) {
    leng += topic.count;
  }
  var i = 0;
  for (const topic of grouped_topics) {
    var tot_size = (topic.count / leng) * 100;
    var _dy2 = 0;
    var _dx2 = 0;

    if (tot_size >= 12) {
      _dy2 = 0;
      _dx2 = 0;
    } else if (tot_size > 11 && tot_size < 12) {
      _dy2 = 20;
      _dx2 = -77;
    } else if (tot_size > 8.5 && tot_size < 11) {
      _dy2 = 20;
      _dx2 = -60;
    } else if (tot_size >= 6 && tot_size <= 8.5) {
      _dy2 = 17;
      _dx2 = -58;
    } else if (tot_size >= 5 && tot_size <= 6) {
      _dy2 = 13;
      _dx2 = -35;
    } else {
      _dy2 = 1;
      _dx2 = -35;
    }

    const topic_color = unique_merchantColors[i];
    i++;

    const chartsDataObj = {
      fillColor: topic_color,
      id: topic.id,
      size: topic.count,
      name: getNameToDisplay(topic.count, topic.name),
      // size: (topic.count * 2.7).toFixed(2),
      // name: topic.name.split(' ').length > 1 ? topic.name.split(' ').join('\n') : topic.name,   //(topic.count * 2.7).toFixed(2) > 3 ? topic.name.split(' ').join('\n') : topic.name,
      dYdX1:
        topic.name.split(" ").length > 1
          ? { dy: 0, dx: -1 }
          : { dy: 0, dx: -1 },
      dYdX2:
        topic.name.split(" ").length > 1
          ? { dy: _dy2, dx: _dx2 }
          : { dy: 0, dx: 0 },
    };
    chartsData.push(chartsDataObj);
  }
  return chartsData;
}

function filterOnlySelectedTopicsToDisplay(chartsData, selectedTopics) {
  const filteredChartsData = [];
  const topicIdsToVisualize = new Set();
  for (const topic of selectedTopics) {
    topicIdsToVisualize.add(topic.id);
  }
  for (const chartData of chartsData) {
    if (topicIdsToVisualize.has(chartData.id)) {
      filteredChartsData.push(chartData);
    }
  }
  return filteredChartsData;
}

function normalizeSizes(data) {
  const sizes = data.map((item) => parseFloat(item.size));
  let totalSizeCount = 0;
  for (const size of sizes) {
    totalSizeCount += size;
  }
  // const minSize = Math.min(...sizes);
  // const maxSize = Math.max(...sizes);

  // // Map the sizes to the new range (1-10)
  const reducedData = data.map((item) => {
    //   const newSize = 1 + ((parseFloat(item.size) - minSize) / (maxSize - minSize)) * 9;
    item.size = parseFloat(((item.size * 1000) / totalSizeCount).toFixed(2));
    return item;
  });

  return reducedData;
  // return data;
}

// changing the bubbleChart data to barchart/ histogram data
function transformTopicsDataToHistogramData(topicsChartData) {
  const barChartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-5 -5 24 24"><path fill="#000000" d="m8 6.641l1.121-1.12a1 1 0 0 1 1.415 1.413L7.707 9.763a.997.997 0 0 1-1.414 0L3.464 6.934A1 1 0 1 1 4.88 5.52L6 6.641V1a1 1 0 1 1 2 0v5.641zM1 12h12a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2z"/></svg>`,
          selection: false,
          zoom: false,
          zoomin: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#000000" d="M17.5 10c0 1.71-.572 3.287-1.536 4.548l4.743 4.745a1 1 0 0 1-1.32 1.497l-.094-.083l-4.745-4.743A7.5 7.5 0 1 1 17.5 10ZM10 5.5a1 1 0 0 0-1 1V9H6.5a1 1 0 1 0 0 2H9v2.5a1 1 0 1 0 2 0V11h2.5a1 1 0 1 0 0-2H11V6.5a1 1 0 0 0-1-1Z"/></svg>`,
          zoomout: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#000000" d="M17.5 10a7.5 7.5 0 1 0-2.952 5.964l4.745 4.743l.094.083a1 1 0 0 0 1.32-1.497l-4.743-4.745A7.468 7.468 0 0 0 17.5 10Zm-4-1a1 1 0 1 1 0 2h-7a1 1 0 1 1 0-2h7Z"/></svg>`,
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            filename: "topics" || undefined,
          },
          svg: {
            filename: "topics" || undefined,
          },
          png: {
            filename: "topics" || undefined,
          },
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
        barHeight:
          topicsChartData && topicsChartData.length < 5 ? "35%" : "85%",
        borderRadius: 10,
      },
    },
    dataLabels: {
      enabled: true,
    },
    grid: {
      show: false,
    },
    legend: {
      show: true,
      position: "bottom",
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: undefined,
        title: {
          formatter: (seriesName) => "%",
        },
      },
    },
  };

  let data = [];
  let colors = [];

  let sortedTopicsChartData = topicsChartData.sort((a, b) => b.size - a.size);

  for (let topicChartData of sortedTopicsChartData) {
    let histogramData = {
      x: topicChartData.name,
      y: +(+topicChartData.size / 2.7).toFixed(2),
    };
    let colorOfBar = topicChartData.fillColor
      ? topicChartData.fillColor
      : "rgb(0, 0, 0)";

    colors.push(colorOfBar);
    data.push(histogramData);
  }

  barChartOptions.colors = colors;
  let barChartData = [
    {
      data,
    },
  ];

  return {
    barChartOptions,
    barChartData,
  };
}

// get top 10 topics on default selections
function getTopDefaultTopicsSelected(topicsData, topicsSelectionOptions) {
  let topTopicsBySize = topicsData?.grouped_topics
    ?.sort((a, b) => b.size - a.size)
    .slice(0, 10);
  let defaultSelectedOptions = [];
  for (let selected = 0; selected < topicsSelectionOptions.length; selected++) {
    for (let topic = 0; topic < topTopicsBySize.length; topic++) {
      if (
        topTopicsBySize[topic].id === topicsSelectionOptions[selected].value
      ) {
        defaultSelectedOptions.push(topicsSelectionOptions[selected]);
      }
    }
  }
  return defaultSelectedOptions;
}

export default function Topics() {
  const [landingPage, setLandingPage] = useState([]);
  const [filterChange, setFilterChange] = useState(false);
  const [isChartsDataUpdated, setIsChartsDataUpdated] = useState(false);
  const [isHistogramDataUpdated, setIsHistogramDataUpdated] = useState(false);
  const [showDefault, setShowDefault] = useState(true);
  const [resetFilters, setResetFilters] = useState(false);

  const customerConfig = useSelector((state) => state.customerConfig);
  const primaryColor =
    customerConfig.configuration &&
    customerConfig.configuration["primary_color"];
  const secondaryColor = toGetColorCode(primaryColor, 3, "white");
  const tertiaryColor = toGetColorCode(primaryColor, 5, "white");
  const seventhColor = toGetColorCode(primaryColor, 7, "white");
  const tenthColorShade = toGetColorCode(primaryColor, 9, "white");

  const topicsSlice = useSelector((state) => state.topics);
  const topicsData = topicsSlice.topicsData;
  const topicsSelectionsForVisualization =
    topicsSlice.topicSelectionsToVisualize;
  const topicsSelectedForVisualization = topicsSlice.topicsSelectedToVisualize;
  const unique_merchantColors = useMemo(() => generateUniqueColors(200), []);

  const topicsDataForCharts = transformTopicsDataToChartFormat(
    topicsData,
    topicsSelectedForVisualization,
    showDefault,
    unique_merchantColors
  );
  let filteredTopicDataForCharts = filterOnlySelectedTopicsToDisplay(
    topicsDataForCharts,
    topicsSelectedForVisualization
  );
  const topicsDataForHistogramCharts = transformTopicsDataToHistogramData(
    filteredTopicDataForCharts
  );
  filteredTopicDataForCharts = normalizeSizes(filteredTopicDataForCharts);
  const topDefaultSelectedTopics = getTopDefaultTopicsSelected(
    topicsData,
    topicsSelectionsForVisualization
  );

  const dispatch = useDispatch();
  const conversationEngagedSlice = useSelector((state) => state.chatRating);
  const total = conversationEngagedSlice.total
    ? conversationEngagedSlice.total
    : 0;
  const filters = useSelector((state) => state.filters);
  const selectedMerchant = useSelector((state) => state.selectedValue);

  const [requiredColorForPage, setRequiredColorForPage] = useState({
    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
    tertiaryColor: tertiaryColor,
    seventhColor: seventhColor,
    tenthColorShade: tenthColorShade,
  });
  const isSelectedMerchant =
    selectedMerchant === "All" ||
    selectedMerchant === "" ||
    selectedMerchant === null
      ? false
      : true;

  const [showTopicsCheckedList, setShowTopicsCheckedList] = useState(false);
  const [topicsCheckedList, setTopicsCheckedList] = useState(() => {
    return topicsSelectionsForVisualization?.map((topic) => {
      return { ...topic, checked: true };
    });
  });
  const topicsCheckedListRef = useRef();

  useEffect(() => {
    const topicsDefaultSelectedMap = {};
    const topicsOptionsMap = {};
    addToolTipDiv();
    addTooltipEvent();

    for (let _topic of topicsSelectedForVisualization) {
      if (!topicsDefaultSelectedMap[_topic.id]) {
        topicsDefaultSelectedMap[_topic.id] = _topic;
      }
    }
    for (let _topic of topicsSelectionsForVisualization) {
      if (!topicsOptionsMap[_topic.id]) {
        topicsOptionsMap[_topic.id] = _topic;
      }
    }

    const defaultSelectedOptionsList = [];
    for (let _topic in topicsOptionsMap) {
      if (_topic in topicsDefaultSelectedMap) {
        defaultSelectedOptionsList.push({
          ...topicsOptionsMap[_topic],
          checked: true,
        });
      } else {
        defaultSelectedOptionsList.push({
          ...topicsOptionsMap[_topic],
          checked: false,
        });
      }
    }

    setTopicsCheckedList(() => {
      // return defaultSelectedOptionsList?.map((topic) => {
      //     return { ...topic}
      // })
      return defaultSelectedOptionsList;
    });
  }, [topicsSelectionsForVisualization]);

  useEffect(() => {
    getTopicDescription();
    filters.reporttype = "weekly";
    //  getAllLandingPages(filters.merchant);
    //  dispatch(getTopics(filters));
  }, []);

  useEffect(() => {
    localStorage.setItem("filters", JSON.stringify(filters));
  }, [filterChange, resetFilters]);

  useEffect(() => {
    setIsChartsDataUpdated(true);
    setTimeout(() => {
      setIsChartsDataUpdated(false);
    }, 0);
  }, [filteredTopicDataForCharts]);

  useEffect(() => {
    setIsChartsDataUpdated(true);
    setTimeout(() => {
      setIsChartsDataUpdated(false);
    }, 0);
  }, [topicsSelectedForVisualization, filteredTopicDataForCharts]);

  useEffect(() => {
    setTimeout(() => {
      setIsChartsDataUpdated(false);
    }, 0);
    const topicSelections = getDistinctTopics(topicsData);
    dispatch({
      type: SET_TOPIC_SELECTION_FOR_VISUALIZATION,
      payload: {
        data: topicSelections,
      },
    });
    if (showDefault && topicsData && topicsData.grouped_topics.length > 0) {
      const topTenTopicsBySize = topicsDataForCharts
        .sort((a, b) => b.size - a.size)
        .slice(0, 10);
      dispatch({
        type: SET_TOPIC_SELECTED_FOR_VISUALIZATION,
        payload: {
          data: [
            ...topTenTopicsBySize.map((topic) => {
              return {
                id: topic.id,
                value: topic.id,
                label: topic.name,
              };
            }),
          ],
        },
      });
      setShowDefault(false);
    }
    // setIsChartsDataUpdated(true)
  }, [topicsData]);

  useEffect(() => {
    // setIsChartsDataUpdated(true);
    setIsHistogramDataUpdated(true);
  }, [topicsData, topicsDataForCharts, topicsSelectedForVisualization]);

  function updateFilterValue(obj, reload = false) {
    dispatch(setFilters(obj));
    setFilterChange(true);
  }
  function updateTopicSelectionsToVisualize(selected) {
    dispatch({
      type: SET_TOPIC_SELECTED_FOR_VISUALIZATION,
      payload: {
        data: selected,
      },
    });
  }

  useEffect(() => {
    if (filterChange === true) {
      setFilterChange(false);
      getAllLandingPages(filters.merchant);
      dispatch(getTopics(filters));
    }
  }, [filterChange]);

  function getAllLandingPages(merchant) {
    var token = Cookies.get("token");
    fetch(`${process.env.REACT_APP_APIURL}/landing_pages`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ merchant_id: merchant }),
    })
      .then((response) => response.json())
      .then((data) => {
        const uniqueArray = Array.from(new Set(data));
        var list = [];
        uniqueArray.map((item, index) => {
          list.push({ value: item, label: item });
        });
        setLandingPage(list);
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor:
          (isSelected || isFocused) && requiredColorForPage.primaryColor
            ? requiredColorForPage.primaryColor
            : "transparent",
        color:
          (isSelected || isFocused) && requiredColorForPage.primaryColor
            ? "#FFFFFF"
            : "#000000",
        // backgroundColor: isSelected ? '#6A1F70' : 'transparent',
        // color: isSelected ? '#FFFFFF' : 'black',
      };
    },
  };

  async function getMerchantConfiguration(merchant_value) {
    const config_values = await getUserConfig(merchant_value);
    if (config_values !== null && config_values !== undefined) {
      const primaryColor = config_values["primary_color"];
      const tertiaryColor = toGetColorCode(primaryColor, 3, "white");
      const seventhColor = toGetColorCode(primaryColor, 7, "white");
      const tenthColorShade = toGetColorCode(primaryColor, 9, "white");
      const secondaryColor = toGetColorCode(primaryColor, 3, "white");

      await setRequiredColorForPage({
        ...requiredColorForPage,
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        tertiaryColor: tertiaryColor,
        seventhColor: seventhColor,
        tenthColor: tenthColorShade,
      });
    }

    if (merchant_value.length === 0) {
      await setRequiredColorForPage({
        ...requiredColorForPage,
        primaryColor: "",
        secondaryColor: "",
        seventhColor: "",
        tertiaryColor: "",
      });
    }
  }

  useEffect(() => {
    if (selectedMerchant !== "All") {
      setRequiredColorForPage({ ...requiredColorForPage });
      // getMerchantConfiguration(customerConfig.configuration, selectedMerchant)
      // getMerchantConfiguration([])

      if (selectedMerchant === "") {
        updateFilterValue({
          merchant: "",
        });
      } else {
        updateFilterValue({
          merchant: [{ label: selectedMerchant, value: selectedMerchant }],
        });
      }

      const primaryColor =
        customerConfig.configuration &&
        customerConfig.configuration["primary_color"];
      const secondaryColor = toGetColorCode(primaryColor, 3, "white");
      const tertiaryColor = toGetColorCode(primaryColor, 5, "white");
      const seventhColor = toGetColorCode(primaryColor, 7, "white");
      const tenthColorShade = toGetColorCode(primaryColor, 9, "white");

      setRequiredColorForPage({
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        seventhColor: seventhColor,
        tertiaryColor: tertiaryColor,
        tenthColor: tenthColorShade,
      });
    } else {
      // getMerchantConfiguration({}, [])
      setRequiredColorForPage({
        ...requiredColorForPage,
        primaryColor: "",
        secondaryColor: "",
        seventhColor: "",
        tertiaryColor: "",
        tenthColor: "",
      });
      updateFilterValue({
        merchant: "",
      });
    }
  }, [selectedMerchant, customerConfig.configuration]);

  function handleResetFilters() {
    setResetFilters(true);
  }

  const checkboxClicked = async (id) => {
    await setTopicsCheckedList((prevCheckedList) => {
      const toSet = prevCheckedList.map((checkedItem) => {
        if (checkedItem.id === id) {
          let checked = !checkedItem.checked;
          checkedItem = { ...checkedItem, checked };
        }
        return checkedItem;
      });
      return toSet;
    });
  };

  function handleSelectedTopics() {
    const selectedList = topicsCheckedList.filter((topic) => topic.checked);
    dispatch({
      type: SET_TOPIC_SELECTED_FOR_VISUALIZATION,
      payload: {
        data: selectedList,
      },
    });
    setShowTopicsCheckedList(false);
  }

  function resetTopicsCheckedList(removedTopic) {
    const resetTopicsList = topicsCheckedList.map((_topic) => {
      if (_topic.id === removedTopic.id) {
        let checked = !_topic.checked;
        return (_topic = { ..._topic, checked });
      }
      return _topic;
    });
    setTopicsCheckedList(resetTopicsList);
  }

  function handleCheckedListClickOutside(event) {
    if (
      topicsCheckedListRef.current &&
      !topicsCheckedListRef.current.contains(event.target)
    ) {
      setShowTopicsCheckedList(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleCheckedListClickOutside, true);
    return () => {
      document.removeEventListener(
        "click",
        handleCheckedListClickOutside,
        true
      );
    };
  }, []);

  function isButtonBackgroundBlur() {
    const topic = topicsCheckedList.some((_topic) => _topic.checked);
    return !topic;
  }

  return (
    <>
      <div className="abc mx-auto px-2">
        <Filters
          filters={filters}
          setFilters={(obj) => updateFilterValue(obj)}
          landingPage={landingPage}
          total={total}
          filterChange={filterChange}
          resetState={resetFilters}
          merchantColors={requiredColorForPage}
          isSelectedMerchant={isSelectedMerchant}
        />
      </div>

      {topicsDataForHistogramCharts.barChartData[0].data.length ? (
        <>
          <div className="flex flex-col justify-start px-2">
            <div className="relative flex flex-row items-center justify-start">
              <h4 className="relative font-poppins text-base font-medium text-secondaryColor">
                Topic's Trends
              </h4>
              <div
                className="dropdown-filters flex h-10 w-36 cursor-pointer items-center justify-center gap-2.5 rounded py-1.5 pr-2 pl-3 font-poppins font-normal font-medium text-[#6A1F70]"
                style={{ color: primaryColor }}
                onClick={() => {
                  setShowTopicsCheckedList(!showTopicsCheckedList);
                  // toggleFilterOptionsDisplay()
                }}
              >
                <AiOutlinePlus />
                Add Topics
              </div>

              {showTopicsCheckedList && (
                <div
                  ref={topicsCheckedListRef}
                  className="top-13 dropdown-filters absolute top-[0] left-[120px] z-10 z-30 mt-10 w-60 rounded border border-[#ECECEC] bg-white py-2 px-2 font-poppins text-base font-normal text-[#1B1B1D] shadow-[#000000]"
                >
                  <div className="dropdown-filters flex flex-col gap-y-4 px-6 pl-2">
                    {topicsCheckedList.map(({ id, checked, value, label }) => (
                      <label
                        className={`dropdown-filters checkboxFilterOption relative cursor-pointer ${
                          checked ? "checkboxFilter" : ""
                        }`}
                        key={id}
                      >
                        <input
                          type="checkbox"
                          className="mr-2"
                          name={id}
                          id={id}
                          value={id}
                          checked={checked}
                          onChange={() => checkboxClicked(id)}
                        />
                        {label}
                      </label>
                    ))}
                    <button
                      onClick={() => {
                        handleSelectedTopics();
                      }}
                      className={`${
                        !isButtonBackgroundBlur()
                          ? "m-background cursor-pointer text-white"
                          : "cursor-not-allowed bg-[#ECECEC] text-[#939393]"
                      } tracking-wide-[0.05em] relative float-right mt-2.5 w-32 rounded-sm px-2 py-1 font-poppins text-xs font-normal leading-5 text-white`}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* <div className=" w-1/2 pt-10 pb-0">
                                <Select
                                    // defaultValue={showDefault && topDefaultSelectedTopics}
                                    isMulti
                                    name="topics"
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    controlShouldRenderValue={false}
                                    components={{
                                        Option, DropdownIndicator
                                    }}
                                    options={topicsSelectionsForVisualization || []}
                                    onChange={(e) => { updateTopicSelectionsToVisualize(e) }}
                                    value={topicsSelectedForVisualization}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    styles={colourStyles}
                                />
                            </div> */}

            <div className="mt-5 flex w-full flex-wrap">
              {topicsSelectedForVisualization &&
                topicsSelectedForVisualization.map((topic, index) => {
                  return (
                    <div
                      className=" relative left-0 mr-2 rounded border-2 px-3 py-2 font-poppins text-sm font-normal text-[#1B1B1D]"
                      style={{
                        marginBottom: "5px",
                        borderColor: requiredColorForPage.primaryColor
                          ? requiredColorForPage.primaryColor
                          : "#6A1F70",
                        background:
                          requiredColorForPage.tenthColor &&
                          requiredColorForPage.tenthColor !== "#ffffff"
                            ? requiredColorForPage.tenthColor
                            : "#FBEFFC",
                      }}
                      // style={{
                      //     // borderColor: primaryColor ? primaryColor : '#6A1F70',
                      //     // background: seventhColor ? seventhColor : '#FBEFFC',
                      //     'marginBottom': '5px',
                      //     background: requiredColorForPage.tenthColor ? requiredColorForPage.tenthColor : !isSelectedMerchant ? '#FBEFFC' : '#ffffff',
                      //     borderColor: requiredColorForPage.primaryColor ? requiredColorForPage.primaryColor : !isSelectedMerchant ? '#6A1F70' : '#ffffff',

                      // }}
                    >
                      <button
                        className="absolute -right-2 -top-2 rounded-full bg-white"
                        onClick={(e) => {
                          e.stopPropagation();
                          resetTopicsCheckedList(topic);
                          const withoutThisTopic = [
                            ...topicsSelectedForVisualization.filter(
                              (_topic) => _topic.label != topic.label
                            ),
                          ];
                          updateTopicSelectionsToVisualize(withoutThisTopic);
                        }}
                      >
                        <div
                          style={{ borderRadius: "100%", overflow: "hidden" }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M15.5 7.56885C15.5 11.7111 12.1423 15.0688 8 15.0688C3.85775 15.0688 0.5 11.7111 0.5 7.56885C0.5 3.4266 3.85775 0.0688477 8 0.0688477C12.1423 0.0688477 15.5 3.4266 15.5 7.56885ZM5.7275 5.29635C5.83297 5.19101 5.97594 5.13184 6.125 5.13184C6.27406 5.13184 6.41703 5.19101 6.5225 5.29635L8 6.77385L9.4775 5.29635C9.58413 5.19699 9.72517 5.14289 9.87089 5.14547C10.0166 5.14804 10.1557 5.20707 10.2587 5.31013C10.3618 5.41319 10.4208 5.55223 10.4234 5.69796C10.426 5.84368 10.3719 5.98472 10.2725 6.09135L8.795 7.56885L10.2725 9.04635C10.3719 9.15298 10.426 9.29401 10.4234 9.43974C10.4208 9.58547 10.3618 9.7245 10.2587 9.82756C10.1557 9.93062 10.0166 9.98966 9.87089 9.99223C9.72517 9.9948 9.58413 9.94071 9.4775 9.84135L8 8.36385L6.5225 9.84135C6.41587 9.94071 6.27483 9.9948 6.12911 9.99223C5.98338 9.98966 5.84434 9.93062 5.74128 9.82756C5.63822 9.7245 5.57919 9.58547 5.57662 9.43974C5.57405 9.29401 5.62814 9.15298 5.7275 9.04635L7.205 7.56885L5.7275 6.09135C5.62216 5.98588 5.56299 5.84291 5.56299 5.69385C5.56299 5.54478 5.62216 5.40182 5.7275 5.29635Z"
                              fill="rgb(174,83,65)"
                            />
                          </svg>
                        </div>
                      </button>
                      <span className="topic_name">{topic.label}</span>
                      <span className="node_description"> &#9432;</span>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="flex flex-row">
            <div style={{ height: "500px", width: "80%" }}>
              {isHistogramDataUpdated ? (
                <BarChart
                  chartData={topicsDataForHistogramCharts.barChartData}
                  chartOptions={topicsDataForHistogramCharts.barChartOptions}
                />
              ) : null}
            </div>
          </div>
        </>
      ) : null}

      {topicsDataForHistogramCharts.barChartData[0].data.length === 0 ? (
        <NoResultFound
          resetFilters={handleResetFilters}
          merchantColors={requiredColorForPage}
          isSelectedMerchant={isSelectedMerchant}
        />
      ) : null}
    </>
  );
}

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className="multiple_option mr-2"
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
