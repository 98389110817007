import React, { useState, useCallback } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from "recharts";

// Generate 15 days of dummy data
const generateDummyData = () => {
  const data = [];
  const startDate = new Date("2023-06-01");
  for (let i = 0; i < 50; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + i);
    data.push({
      date: currentDate.toISOString().split("T")[0],
      visits: Math.floor(Math.random() * 1000) + 500,
      leads: Math.floor(Math.random() * 200) + 100,
      engagement: Math.floor(Math.random() * 500) + 200,
      conversions: Math.floor(Math.random() * 50) + 20,
    });
  }
  return data;
};

const data = generateDummyData();

const colors = {
  visits: "#8884d8",
  leads: "#82ca9d",
  engagement: "#ffc658",
  conversions: "#ff7300",
};

export default function InteractiveLineChart() {
  const [mode, setMode] = useState("absolute");
  const [metrics, setMetrics] = useState([
    "visits",
    "leads",
    "engagement",
    "conversions",
  ]);

  const handleModeChange = (newMode) => {
    setMode(newMode);
  };

  const handleMetricToggle = (metric) => {
    setMetrics((prev) =>
      prev.includes(metric)
        ? prev.filter((m) => m !== metric)
        : [...prev, metric]
    );
  };

  const calculatePercentage = useCallback((value, total) => {
    return (value / total) * 100;
  }, []);

  const processedData = data.map((item) => {
    const total = item.visits + item.leads + item.engagement + item.conversions;
    return {
      ...item,
      visits_percent: calculatePercentage(item.visits, total),
      leads_percent: calculatePercentage(item.leads, total),
      engagement_percent: calculatePercentage(item.engagement, total),
      conversions_percent: calculatePercentage(item.conversions, total),
    };
  });

  return (
    <div className="mx-auto w-full max-w-4xl rounded-lg bg-white p-4 shadow-lg">
      <h2 className="mb-4 text-2xl font-bold">
        Metrics Dashboard
      </h2>
      <div className="mb-4 flex space-x-2">
        <button
          className={`rounded px-4 py-2 ${
            mode === "absolute" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
          onClick={() => handleModeChange("absolute")}
        >
          Absolute
        </button>
        <button
          className={`rounded px-4 py-2 ${
            mode === "percentage" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
          onClick={() => handleModeChange("percentage")}
        >
          Percentage
        </button>
      </div>
      <div className="mb-4 flex flex-wrap gap-4">
        {Object.keys(colors).map((metric) => (
          <label key={metric} className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={metrics.includes(metric)}
              onChange={() => handleMetricToggle(metric)}
              className="form-checkbox"
            />
            <span className="capitalize">{metric}</span>
          </label>
        ))}
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={processedData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          {metrics.map((metric) => (
            <Line
              key={metric}
              type="monotone"
              dataKey={mode === "absolute" ? metric : `${metric}_percent`}
              stroke={colors[metric]}
              activeDot={{ r: 8 }}
              strokeWidth={3}
            />
          ))}
          <Brush dataKey="date" height={30} stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
