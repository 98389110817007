import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ColumnManagementModal = ({
  open,
  onClose,
  availableColumns = [],
  visibleColumns = [],
  onSave,
}) => {
  const [currentVisibleColumns, setCurrentVisibleColumns] =
    useState(visibleColumns);
  const [currentAvailableColumns, setCurrentAvailableColumns] = useState([]);

  const initialStateRef = useRef({
    visibleColumns: visibleColumns,
    availableColumns: [],
  });

  useEffect(() => {
    const columnsArray = Array.isArray(availableColumns)
      ? availableColumns
      : [];
    const hiddenColumns = columnsArray.filter(
      (col) => !visibleColumns.some((visCol) => visCol.value === col.value)
    );

    setCurrentAvailableColumns(hiddenColumns);

    if (!initialStateRef.current.availableColumns.length) {
      initialStateRef.current = {
        visibleColumns,
        availableColumns: hiddenColumns,
      };
    }
  }, [availableColumns, visibleColumns]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const newVisibleColumns = Array.from(currentVisibleColumns);
    const newAvailableColumns = Array.from(currentAvailableColumns);

    const [removed] =
      source.droppableId === "visible"
        ? newVisibleColumns.splice(source.index, 1)
        : newAvailableColumns.splice(source.index, 1);

    if (destination.droppableId === "visible") {
      newVisibleColumns.splice(destination.index, 0, removed);
    } else {
      newAvailableColumns.splice(destination.index, 0, removed);
    }

    setCurrentVisibleColumns(newVisibleColumns);
    setCurrentAvailableColumns(newAvailableColumns);
  };

  const handleSave = () => {
    onSave(currentVisibleColumns, currentAvailableColumns);
  };

  const resetToInitialState = () => {
    setCurrentVisibleColumns(initialStateRef.current.visibleColumns);
    setCurrentAvailableColumns(initialStateRef.current.availableColumns);
  };

  const renderEmptyState = () => (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ padding: "20px" }}
    >
      No columns available
    </Typography>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h5" align="center" style={{ fontWeight: "bold" }}>
          Manage Columns
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DragDropContext onDragEnd={onDragEnd}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                align="center"
                style={{ fontWeight: "bold", fontSize: "1rem" }}
              >
                Visible Columns
              </Typography>
              <Droppable droppableId="visible">
                {(provided) => (
                  <List
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      border: "1px solid #ddd",
                      minHeight: "300px", // 20% of modal height
                      maxHeight: "300px",
                      overflow: "auto",
                    }}
                  >
                    {currentVisibleColumns.length > 0
                      ? currentVisibleColumns.map((column, index) => (
                          <Draggable
                            key={column.value}
                            draggableId={column.value}
                            index={index}
                          >
                            {(provided) => (
                              <ListItem
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  userSelect: "none",
                                  backgroundColor: "#f0f0f0",
                                  margin: "4px 0",
                                }}
                              >
                                {column.label}
                              </ListItem>
                            )}
                          </Draggable>
                        ))
                      : renderEmptyState()}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                align="center"
                style={{ fontWeight: "bold", fontSize: "1rem" }}
              >
                Hidden Columns
              </Typography>
              <Droppable droppableId="hidden">
                {(provided) => (
                  <List
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      border: "1px solid #ddd",
                      minHeight: "300px", // 20% of modal height
                      maxHeight: "300px",
                      overflow: "auto",
                    }}
                  >
                    {currentAvailableColumns.length > 0
                      ? currentAvailableColumns.map((column, index) => (
                          <Draggable
                            key={column.value}
                            draggableId={column.value}
                            index={index}
                          >
                            {(provided) => (
                              <ListItem
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  userSelect: "none",
                                  backgroundColor: "#f0f0f0",
                                  margin: "4px 0",
                                }}
                              >
                                {column.label}
                              </ListItem>
                            )}
                          </Draggable>
                        ))
                      : renderEmptyState()}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </Grid>
          </Grid>
        </DragDropContext>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={resetToInitialState}
          variant="outlined"
          color="secondary"
        >
          Reset All
        </Button>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ColumnManagementModal;
