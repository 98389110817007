import { useEffect, useState, useRef } from "react";
import ChatIcon from "components/icons/ChatIcon";
import ChatConversionIcon from "components/icons/ChatConversionIcon";
// import ChatRatingIcon from "components/icons/ChatRatingIcon";
import ChatLeadIcon from "components/icons/ChatLeadIcon";
import TurnsChatIcon from "components/icons/TurnsChatIcon";
import AvgTimeIcon from "components/icons/AvgTimeIcon";
import Filters from "../../../components/filters";
import NoResultFound from "components/noResultFound/NoResultFound";
import ShowHideColumns from "components/tableInfo/ShowHideColumns";
import DownloadTableData from "components/tableInfo/DownloadTableData";

import {
  getUserConfig,
  getAllFilterValues,
  getAllLandingPages,
  getAllMetrics,
} from "utils/utils";
import toGetColorCode from "hooks/useToGetColorCode";
import NoResult from "../../../components/noResultFound/NoResult";

import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";

import { useSelector, useDispatch } from "react-redux";
import { getAllConversations, setFilters } from "store/actions";
import ListItemCount from "../../../components/listItemCount/ListItemCount";

const Dashboard = () => {
  const dispatch = useDispatch();
  const selectedValue = useSelector((state) => state.selectedValue);
  const [resetFilters, setResetFilters] = useState(false);
  const [metrics, setMetrics] = useState({});
  const [landingPage, setLandingPage] = useState([]);
  const [page, setPage] = useState(1);
  const [columnsDataCheck, setColumnsDataCheck] = useState(false);
  const [isAsending, setIsAsending] = useState(false);
  const [filterChange, setFilterChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userPermission, setUserPermission] = useState([]);
  const [role, setRole] = useState("");
  const headers = useSelector((state) => state.tableHeaders);
  const feedback_headers = useSelector((state) => state.feedbackTableHeaders);
  const transcript_headers = useSelector(
    (state) => state.TranscriptTableHeaders
  );
  const defaultColumnsToDisplay = localStorage.getItem("columns_for_table")
    ? JSON.parse(localStorage.getItem("columns_for_table"))
    : formatColumnOptions();
  const [selectedColumnsToDisplay, setSelectedColumnsToDisplay] = useState(
    defaultColumnsToDisplay
  );
  const [noData, setNoData] = useState(false);

  const conversationEngagedSlice = useSelector((state) => state.default);
  const total = conversationEngagedSlice.total
    ? conversationEngagedSlice.total
    : 0;
  let tableList = conversationEngagedSlice.conversations
    ? conversationEngagedSlice.conversations
    : [];
  const isTableDataLoading = conversationEngagedSlice.isLoading;
  const tableDataPageNumber = conversationEngagedSlice.pageNumber || 1;
  const filters = useSelector((state) => state.filters);
  const totalRowsToDisplayInTable = conversationEngagedSlice.total;
  const hasMoreDataToLoadInTable =
    isTableDataLoading ||
    (conversationEngagedSlice?.conversations &&
      conversationEngagedSlice.conversations.length ===
        totalRowsToDisplayInTable)
      ? false
      : true;
  const selectedMerchant = useSelector((state) => state.selectedValue);
  const selectedTimezone = useSelector((state) => state.selectedTimezone);
  const customerConfig = useSelector((state) => state.customerConfig);

  const primaryColor =
    customerConfig.configuration &&
    customerConfig.configuration["primary_color"];
  const secondaryColor = toGetColorCode(primaryColor, 3, "white");
  const tertiaryColor = toGetColorCode(primaryColor, 5, "white");
  const seventhColor = toGetColorCode(primaryColor, 7, "white");

  const [requiredColorForPage, setRequiredColorForPage] = useState({
    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
    tertiaryColor: tertiaryColor,
    seventhColor: seventhColor,
  });

  const isSelectedMerchant =
    selectedMerchant === "All" ||
    selectedMerchant === "" ||
    selectedMerchant === null
      ? false
      : true;

  useEffect(() => {
    //  updateFilterValue(filters)
    //  getAllLandingPages(filters.merchant, setLandingPage);
    //sendRequestToBackend(1);
    //getMetrics()
    getUserPermissions();
  }, []);

  useEffect(() => {
    localStorage.setItem("filters", JSON.stringify(filters));
  }, [filterChange]);

  const fetchMoreTableData = async () => {
    var current_page = page + 1;
    await setPage(current_page);
    setIsLoading(true);
    await sendRequestToBackend(current_page, true);
  };

  async function updateFilterValue(obj) {
    setIsLoading(true);
    await dispatch(setFilters(obj));
    await setFilterChange(true);
  }

  function getUserPermissions() {
    var permission = localStorage.getItem("permission");
    setRole(localStorage.getItem("role"));
    if (
      permission !== "{}" &&
      permission !== null &&
      permission !== undefined
    ) {
      var json = JSON.parse(permission);

      var permission_lists = [];
      json.forEach((val, index) => {
        permission_lists.push(val["value"]);
      });
      setUserPermission(permission_lists);
    }
  }

  useEffect(() => {
    if (filterChange === true) {
      setFilterChange(false);
      setPage(1);
      //console.log("filter Change --- ", filters.merchant)
      dispatch({
        type: "RESET_ALL_CONVERSATIONS",
      });
      //console.log(1)
      getMetrics();
      sendRequestToBackend(1, true);
      getAllLandingPages(filters.merchant, setLandingPage);
    }
  }, [filterChange]);

  async function getMetrics() {
    //console.log(2)
    let object = await getAllFilterValues(page, filters);
    getAllMetrics(object, setMetrics);
  }

  async function sendRequestToBackend(
    page_no = "",
    is_by_filter_change = false,
    merchant
  ) {
    let object = await getAllFilterValues(page, filters, page_no);
    if (merchant) {
      object = { ...object, merchant: merchant };
    }

    let toUpdate = {
      isLoading: tableList.length ? false : true,
      pageNumber: page_no,
    };
    if (tableDataPageNumber != 1 || is_by_filter_change) {
      toUpdate.isLoading = true;
    }

    if (toUpdate.isLoading) {
      await dispatch({
        type: "UPDATE_ALL_CONVERSATION_LIST",
        payload: {
          // page: page_no,
          toUpdate: {
            isLoading: true,
          },
        },
      });
      await dispatch(getAllConversations(object));
      await dispatch({ type: "SET_LOADING_LIST", payload: false });
    }
  }

  async function sortFunction(field) {
    let asending = true;
    await setIsAsending((prevValue) => {
      asending = prevValue;
      return prevValue;
    });

    await setIsAsending(!asending);
    updateFilterValue({ sort: asending ? "ASC" : "DESC", sorting: field });
  }

  const colorStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor:
          (isSelected || isFocused) && requiredColorForPage.primaryColor
            ? requiredColorForPage.primaryColor
            : "transparent",
        color:
          (isSelected || isFocused) && requiredColorForPage.primaryColor
            ? "#FFFFFF"
            : "#000000",
      };
    },
  };

  async function getMerchantConfiguration(merchant_value) {
    const config_values = await getUserConfig(merchant_value);

    if (config_values !== null && config_values !== undefined) {
      const primaryColor = config_values["primary_color"];
      const secondaryColor = toGetColorCode(primaryColor, 3, "white");
      const tertiaryColor = toGetColorCode(primaryColor, 5, "white");
      const seventhColor = toGetColorCode(primaryColor, 7, "white");

      await setRequiredColorForPage({
        ...requiredColorForPage,
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        seventhColor: seventhColor,
        tertiaryColor: tertiaryColor,
      });
    }

    if (merchant_value.length == 0) {
      await setRequiredColorForPage({
        ...requiredColorForPage,
        primaryColor: "",
        secondaryColor: "",
        seventhColor: "",
        tertiaryColor: "",
      });
    }
  }

  useEffect(() => {
    if (selectedMerchant !== "All") {
      // getMerchantConfiguration(selectedMerchant)
      // getMerchantConfiguration([])
      if (selectedMerchant == "") {
        updateFilterValue({
          merchant: "",
        });
      } else {
        updateFilterValue({
          merchant: [{ label: selectedMerchant, value: selectedMerchant }],
        });
      }

      const primaryColor =
        customerConfig.configuration &&
        customerConfig.configuration["primary_color"];
      const secondaryColor = toGetColorCode(primaryColor, 3, "white");
      const tertiaryColor = toGetColorCode(primaryColor, 5, "white");
      const seventhColor = toGetColorCode(primaryColor, 7, "white");
      setRequiredColorForPage({
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        seventhColor: seventhColor,
        tertiaryColor: tertiaryColor,
      });
    } else {
      //getMerchantConfiguration([])
      setRequiredColorForPage({
        ...requiredColorForPage,
        primaryColor: "",
        secondaryColor: "",
        seventhColor: "",
        tertiaryColor: "",
      });

      updateFilterValue({
        merchant: "",
      });
    }
  }, [selectedMerchant, customerConfig.configuration, selectedTimezone]);

  function formatColumnOptions() {
    const columnsOptions = headers
      ?.filter((col) => col.key != "conversation_id")
      ?.map((col, index) => {
        return { id: index, value: col.key, label: col.label };
      });
    return columnsOptions;
  }

  async function handleResetFilters() {
    await setResetFilters(true);
    setResetFilters(false);
    await setFilterChange(true);
  }

  function handleColumnSelection(value) {
    value = value.sort(
      (a, b) =>
        headers.findIndex((header) => header.key == a.value) -
        headers.findIndex((header) => header.key == b.value)
    );
    if (!value || !value.length) {
      localStorage.removeItem("columns_for_table");
    } else {
      localStorage.setItem("columns_for_table", JSON.stringify(value));
    }
    setSelectedColumnsToDisplay(value);
  }
  //console.log(metrics, 'metrics')
  return (
    <div className="">
      {/* Card widget */}

      <div className="abc mx-auto px-2 pb-5 pt-0">
        <Filters
          filters={filters}
          setFilters={(obj) => updateFilterValue(obj)}
          landingPage={landingPage}
          total={total}
          filterChange={filterChange}
          resetState={resetFilters}
          merchantColors={requiredColorForPage}
          isSelectedMerchant={isSelectedMerchant}
        />
      </div>

      <div className="mt-3 grid w-full grid-cols-1 gap-[8px] md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 3xl:grid-cols-6">
        {(userPermission.includes("chat_engagement") || role !== "user") && (
          // TODO: make this charts redirect to certain tab by enable url property
          <Widget
            icon={
              <ChatIcon
                className="text-dark h-7 w-7"
                fill="red"
                style={{
                  // color: isSelectedMerchant && !merchantColors.secondaryColor ? '#6A1F70 !important' : '#ffffff',
                  color: "blue",
                }}
              />
            }
            title={"Chat Engagement"}
            // url={"/admin/chat_engagement"}
            subtitle={
              Object.keys(metrics).length !== 0 &&
              metrics.user_engaged !== null &&
              metrics.user_engaged !== 0
                ? (
                    (metrics.user_engaged / metrics.unique_conversation) *
                    100
                  ).toFixed(2) + "%"
                : "-"
            }
            tag={
              Object.keys(metrics).length !== 0 &&
              metrics.user_engaged !== null &&
              metrics.user_engaged !== 0
                ? `${metrics.user_engaged}/${metrics.unique_conversation}`
                : ""
            }
            merchantColors={requiredColorForPage}
            isSelectedMerchant={isSelectedMerchant}
          />
        )}
        {(userPermission.includes("chat_conversation") || role !== "user") && (
          <Widget
            icon={<ChatConversionIcon className="h-6 w-6 text-white" />}
            title={"Leads from Chat"} //***** */
            // url={"/admin/leads_from_chat"}
            subtitle={
              Object.keys(metrics).length !== 0 && metrics?.lead_event_count
                ? metrics?.lead_event_count
                : "-"
            }
            merchantColors={requiredColorForPage}
            isSelectedMerchant={isSelectedMerchant}
          />
        )}
        {/* {(userPermission.includes("chat_rating") || role !== 'user') &&
          <Widget
            icon={<ChatRatingIcon className="h-7 w-7 text-white" />}
            title={"Chat Rating"}
            url={"/admin/chat_rating"}
            subtitle={
              (Object.keys(metrics).length !== 0 && metrics.distinct_conversation_rating !== null && metrics.distinct_conversation_rating !== 0) ?
                (metrics.total_rating / metrics.distinct_conversation_rating).toFixed(1) : "-"
            }
            tag={
              (Object.keys(metrics).length !== 0 && metrics.distinct_conversation_rating !== null && metrics.distinct_conversation_rating !== 0)
                ? `${metrics.distinct_conversation_rating}`
                : ""
            }
          merchantColors={requiredColorForPage}
          isSelectedMerchant={isSelectedMerchant}
          />
        } */}
        {(userPermission.includes("leads") || role !== "user") && (
          <div>
            <Widget
              icon={<ChatLeadIcon className="h-6 w-6" />}
              title={"Chat conversion"}
              // url={"/admin/leads"}
              subtitle={
                metrics &&
                typeof metrics?.conversion_event_count === "number" &&
                Number(metrics?.user_engaged)
                  ? (
                      (metrics?.conversion_event_count /
                        Number(metrics?.user_engaged)) *
                      100
                    ).toFixed(2) + "%"
                  : "-"
              }
              tag={
                metrics &&
                typeof metrics?.conversion_event_count === "number" &&
                Number(metrics?.user_engaged)
                  ? `${metrics?.conversion_event_count}/${metrics?.user_engaged}`
                  : ""
              }
              merchantColors={requiredColorForPage}
              isSelectedMerchant={isSelectedMerchant}
            />
          </div>
        )}
        {(userPermission.includes("leads") || role !== "user") && (
          <Widget
            icon={<ChatConversionIcon className="h-6 w-6" />}
            title={"Visit conversion"}
            // url={"/admin/conversion"}
            subtitle={
              metrics &&
              typeof metrics?.conversion_event_count === "number" &&
              typeof metrics?.unique_conversation === "number"
                ? (
                    (metrics?.conversion_event_count /
                      metrics?.unique_conversation) *
                    100
                  ).toFixed(2) + "%"
                : "-"
            }
            tag={
              metrics &&
              typeof metrics?.conversion_event_count === "number" &&
              typeof metrics?.unique_conversation === "number"
                ? `${metrics?.conversion_event_count}/${metrics?.unique_conversation}`
                : ""
            }
            merchantColors={requiredColorForPage}
            isSelectedMerchant={isSelectedMerchant}
          />
        )}
        {(userPermission.includes("turns_chat") || role !== "user") && (
          <Widget
            icon={<TurnsChatIcon className="h-7 w-7" />}
            title={"Avg turns/chat"}
            // url={"/admin/turn_chat"}
            subtitle={
              Object.keys(metrics).length !== 0 &&
              metrics.unique_conversation !== null &&
              metrics.unique_conversation !== 0 &&
              metrics.turn_count !== 0 &&
              metrics.user_engaged !== "0"
                ? Math.round(metrics.turn_count / metrics.user_engaged)
                : "-"
            }
            merchantColors={requiredColorForPage}
            isSelectedMerchant={isSelectedMerchant}
          />
        )}
        {(userPermission.includes("time_to_answer") || role !== "user") && (
          <Widget
            icon={<AvgTimeIcon className="h-6 w-6" />}
            // url={"/admin/time_to_answer"}
            title={"Avg time to answer"}
            subtitle={
              Object.keys(metrics).length !== 0 &&
              metrics.avg_turn_time !== null
                ? Math.round(metrics.avg_turn_time) + "s"
                : "-"
            }
            merchantColors={requiredColorForPage}
            isSelectedMerchant={isSelectedMerchant}
          />
        )}
      </div>

      <div className="mt-5 grid grid-cols-1 gap-1">
        <div className="flex justify-between">
          <div className="">
            <ListItemCount
              total={total}
              merchantColors={requiredColorForPage}
            />
          </div>
          <div className="z-30 flex gap-2">
            {/* <ShowHideColumns
              formatColumnOptions={formatColumnOptions}
              handleColumnSelection={handleColumnSelection}
              selectedColumnsToDisplay={selectedColumnsToDisplay}
              colorStyles={colorStyles}
            /> */}
            <DownloadTableData
              requiredColorForPage={requiredColorForPage}
              isSelectedMerchant={isSelectedMerchant}
              headers={headers}
              csvType=""
              filters={filters}
              label="Conversation"
              setNoData={setNoData}
            />
            <DownloadTableData
              requiredColorForPage={requiredColorForPage}
              isSelectedMerchant={isSelectedMerchant}
              headers={transcript_headers}
              csvType=""
              filters={filters}
              label="Transcripts"
              type="transcripts"
              setNoData={setNoData}
            />
            <DownloadTableData
              requiredColorForPage={requiredColorForPage}
              isSelectedMerchant={isSelectedMerchant}
              headers={feedback_headers}
              csvType=""
              filters={filters}
              label="Feedback"
              type="feedback"
              setNoData={setNoData}
            />
          </div>
        </div>
        <div>
          {!(
            !isTableDataLoading &&
            tableList &&
            tableList.length === 0 &&
            totalRowsToDisplayInTable === 0
          ) && (
            <CheckTable
              columnsToShow={selectedColumnsToDisplay}
              columnsData={!isTableDataLoading}
              tableData={tableList}
              total={total}
              page={page}
              sortFunction={sortFunction}
              filters={filters}
              isLoading={isTableDataLoading}
              fetchMoreData={() => fetchMoreTableData()}
              totalRowsToDisplay={totalRowsToDisplayInTable}
              hasMoreDataToLoad={hasMoreDataToLoadInTable}
              merchantColors={requiredColorForPage}
              selectedTimezone={filters.timezone}
              isSelectedMerchant={isSelectedMerchant}
            />
          )}

          {!isTableDataLoading && tableList && tableList.length == 0 && (
            <NoResultFound
              resetFilters={handleResetFilters}
              merchantColors={requiredColorForPage}
              isSelectedMerchant={isSelectedMerchant}
            />
          )}
        </div>
      </div>
      {noData && <NoResult setNoData={setNoData} />}
    </div>
  );
};

export default Dashboard;
