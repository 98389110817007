import React from "react";
import { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Header from "components/header";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import ChatScreen from "views/admin/chat";
import { getStyles, getMainLogo, getMerchantConfig } from "../../utils/utils";
import toGetColorCode from "hooks/useToGetColorCode";

export default function Chat(props) {
  const [logoUrl, setLogoUrl] = useState("");
  const [customStyles, setCustomStyles] = React.useState({
    "--primary-color": "#228800",
  });
  const [config, setConfig] = useState({});

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    let mid = query.get("mid");

    getConfig(mid);
  }, []);

  async function getConfig(mid) {
    const configuration = await getMerchantConfig(mid);
    //console.log("configuration", configuration)
    await setConfig(configuration);

    var styles = {
      "--primary-color": configuration["styles"]["primary_color"],
      "--primary-light-color": toGetColorCode(
        configuration["styles"]["primary_color"],
        3,
        "white"
      ),
      "--header_background": configuration["styles"]["header_background"],
      "--secondary-color": configuration["styles"]["secondary_color"],
    };
    await setCustomStyles(styles);
    setLogoUrl(configuration.main_logo);
    setFavicon(configuration.chat_logo);
  }

  function setFavicon(url) {
    const head = document.head;
    const existingFavicon = head.querySelector('link[rel="icon"]');

    if (existingFavicon) {
      head.removeChild(existingFavicon);
    }

    const newFavicon = document.createElement("link");
    newFavicon.rel = "icon";
    newFavicon.href = url;

    head.appendChild(newFavicon);
  }

  return (
    <div className="flex h-full w-full" style={customStyles}>
      {logoUrl && <Header logoUrl={logoUrl} />}
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-white dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] mt-[94px] flex-none transition-all md:pr-2`}
        >
          {/* Routes */}
          <div className="h-full">
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <ChatScreen logourl={logoUrl} config={config} />
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
