import { useEffect, useState } from "react";
import Icon from "../../../assets/svg/icon.svg";
import ThumbIcon from "views/admin/chat/components/ThumbIcon";
import MessageIcon from "views/admin/chat/components/MessageIcon";
import thumbIconEval from "../../../assets/svg/thumbsup_eval.svg";
import messageIconEval from "../../../assets/svg/message_eval.svg";
import Feedback from "views/admin/chat/components/Feedback";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import Cookies from "js-cookie";
import { getHTMLFromURL } from "../../../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import { changeTimezone } from "utils/utils";

const Chat = (props) => {
  const [conversationList, setConversationList] = useState([]);
  const [indexValue, setIndexValue] = useState("");
  const [showFeedback, setShowFeedback] = useState(false);
  const [showUserFeedback, setShowUserFeedback] = useState(false);
  const [selectedTurnid, setSelectedTurnid] = useState(-1);
  const [userRating, setUserRating] = useState({
    user_rating: "",
    user_feedback: "",
  });
  const [markupText, setMarkupText] = useState("");
  const [mobileMarkupText, setMobileMarkupText] = useState("");
  const filters = useSelector((state) => state.filters);
  let tmp_date = "";

  useEffect(() => {
    __init();
    //console.log(ThumbIcon)

    const handleClick = async (e) => {
      let l_tmp = e.target;
      // setSelectedTurnid(-1);
      if (l_tmp.classList.contains("showFeedback")) {
        setSelectedTurnid(l_tmp.getAttribute("data-attr"));
      }
      if (l_tmp.classList.contains("showFeedbackicon")) {
        const parentWithClass = e.target.closest(".showFeedback");

        setSelectedTurnid(parentWithClass.getAttribute("data-attr"));
      }
      if (
        l_tmp.classList.contains("bot-msg-div") ||
        l_tmp.classList.contains("user-msg") ||
        l_tmp.classList.contains("all-messages") ||
        l_tmp.classList.contains("user-message")
      ) {
        setSelectedTurnid(-1);
      }
    };

    const iframeEventHandler = async (e) => {
      if (
        e.data.hasOwnProperty("frameHeight") &&
        e.data.hasOwnProperty("iframeId")
      ) {
        var page_iframe;
        page_iframe = document.querySelector(".spacing #" + e.data.iframeId);
        if (page_iframe) {
          page_iframe.style.height = e.data.frameHeight + "px";
          page_iframe.style.maxHeight = e.data.frameHeight + "px";
          //console.log("height message from parent: ", page_iframe.style.height)
        }
      }
    };

    document.addEventListener("click", handleClick);
    window.addEventListener("message", iframeEventHandler, false);

    return () => {
      document.removeEventListener("click", handleClick);
      window.removeEventListener("message", iframeEventHandler, false);
    };
  }, []);

  async function __init() {
    const query = new URLSearchParams(window.location.search);
    let cid = query.get("cid");
    var object = { cid: cid };
    await getConversations(object);
  }

  function getConversations(object) {
    var token = Cookies.get("token");
    fetch(`${process.env.REACT_APP_APIURL}/getconversation`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(object),
    })
      .then((response) => response.json())
      .then((data) => {
        setConversationList(data[0]["record"]);
        setUserRating({
          user_rating: data[0]["rating_record"][0]["user_rating"],
          user_feedback: data[0]["rating_record"][0]["user_feedback"],
        });
        if (data[0]["merchant_record"] !== "") {
          var link = data[0]["merchant_record"][0]["config"];
          if (link !== "") {
            var config = JSON.parse(link);
            var stylesheet = config["stylesheet_url"];
            addStylesheet(stylesheet);
          }
          addCustomStyles(data[0]["merchant_record"]);
        }
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  function addCustomStyles(custom_styles) {
    const styleElement = document.createElement("style");
    let custom_css = custom_styles[0]["custom_styles"];
    if (custom_css !== "") {
      // Add your custom styles
      const customStyles = `
        ${custom_styles[0]["custom_styles"]}
      `;

      // Set the content of the style element to your custom styles
      styleElement.innerHTML = customStyles;

      // Append the style element to the head of the document
      document.head.appendChild(styleElement);
    }
    // Cleanup function to remove the style element when the component unmounts
    return () => {
      document.head.removeChild(styleElement);
    };
  }

  function addStylesheet(fileName) {
    var head = document.head;
    var link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = fileName;

    head.appendChild(link);
  }

  function submitFeedback(
    rating,
    turn_id,
    conversation_id,
    feedbacktext = "",
    feedback_type = "rating"
  ) {
    setSelectedTurnid(-1);
    var object = {
      conversation_id: conversation_id,
      rating: rating,
      turn_id: turn_id,
      feebacktext: feedbacktext,
      feedbacktype: feedback_type,
    };
    refreshConversationRating(rating, turn_id, conversation_id, feedbacktext);
    var token = Cookies.get("token");
    fetch(`${process.env.REACT_APP_APIURL}/feedback`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(object),
    })
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {
        //console.error(error);
      });
  }

  useEffect(() => {}, []);

  function refreshConversationRating(
    rating,
    turn_id,
    conversation_id,
    feedbacktext
  ) {
    var tmp = conversationList;
    var newlist = [];
    tmp.map(function (row, index) {
      if (turn_id === row.turn_id) {
        if (rating != "") {
          tmp[index]["evaluator_rating"] = rating;
        } else {
          tmp[index]["evaluator_feedback"] = feedbacktext;
        }
      }
    });
    setConversationList(tmp);
  }

  function setMarkupContents(html, title) {
    const element = document.getElementById("dynamicContentDiv");
    element.innerText = "";
    if (window.innerWidth < 768) {
      setMobileMarkupText(html);
    } else {
      setMarkupText(html);
    }
  }

  async function setIframeContent(html, link, title, iframe) {
    const element = document.getElementById("dynamicContentDiv");
    element.innerText = "Loading...";
    setMarkupText(" ");
    if (link !== "" && link !== null && link !== undefined) {
      html = await getHTMLFromURL(link);
    }

    element.innerText = "";
    const newDiv = document.createElement("div");
    newDiv.classList.add("spacing");

    var newIframe = document.createElement("iframe");
    newIframe.style.width = "100%";
    newIframe.frameBorder = "0";
    newIframe.setAttribute("id", iframe);
    newDiv.appendChild(newIframe);

    element.appendChild(newDiv);

    // Load the HTML content into the new iframe
    newIframe.contentWindow.document.open();
    newIframe.contentWindow.document.write(html);
    newIframe.contentWindow.document.close();
  }

  return (
    <div className="mx-auto my-0 grid max-w-[1272px] grid-cols-6 gap-3">
      <div className="col-span-3 hidden md:block lg:col-span-4 ">
        <div id="dynamicContentDiv" className="mt-[46px] max-w-[664px]"></div>
        <div className="mt-[46px] max-w-[664px]">
          {markupText === "" && (
            <h4 className="font-Inter text-[42px] font-bold leading-[56px]">
              {/* Everything you need to run your business */}
            </h4>
          )}
          {markupText === "" && (
            <div className="pt-6">
              {/* Smarter, faster, easier. Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor */}
            </div>
          )}
          {markupText !== "" && (
            <div className="pt-6">
              <ReactMarkdown
                children={markupText}
                rehypePlugins={[rehypeRaw]}
              />
            </div>
          )}
        </div>
      </div>
      <div className="col-span-6 md:col-span-3 lg:col-span-2">
        {/* Card widget */}
        <div
          id="VurbalizeCode"
          className="fixed right-[4vw] flex min-h-[85%] flex-col items-center justify-center text-gray-800"
        >
          <div className="chat-container maximize clover-chat-container flex !h-[70vh] !min-h-[60vh] !min-w-full !max-w-full flex-grow flex-col overflow-hidden rounded-lg border-b border-black bg-white shadow-7xl md:!min-w-[auto] md:!max-w-[550px]">
            {props.config !== null && (
              <div className="header m-background bg-green-900 p-3 text-2xl font-normal text-white">
                {props.config.chat_widget_title}
              </div>
            )}
            <div className="messages all-messages relative flex h-0 flex-grow flex-col overflow-auto px-6 pt-4 pb-[76px]">
              {conversationList.length > 0 &&
                conversationList.map((row, index) => {
                  var message = row.message.replace(
                    "User received message",
                    ""
                  );
                  message = message.replace("User send message", "");
                  //                message = message.replace(/['"]+/g, '')
                  if (
                    row.turn_actor === "bot" ||
                    row.turn_actor === "welcome" ||
                    row.turn_actor === "agent"
                  ) {
                    var agent_image = null;
                    if (
                      row.attributes !== null &&
                      row.attributes !== undefined
                    ) {
                      var attributes = JSON.parse(row.attributes);
                      agent_image = attributes["image_url"];
                    }

                    let isRating = true;
                    let isEvaluator = false;
                    if (
                      (row.user_rating === null || row.user_rating === 0) &&
                      (row.user_feedback === null || row.user_feedback === "")
                    ) {
                      isRating = false;
                    }
                    if (
                      row.evaluator_rating !== null ||
                      row.evaluator_feedback !== null
                    ) {
                      isEvaluator = true;
                    }
                    let suggestion = [];
                    if (row.suggestion_chips_btn !== "") {
                      suggestion = JSON.parse(row.suggestion_chips_btn);
                      //console.log("suggestion",suggestion)
                    }
                    return (
                      <div className="bot-msg relative" key={index}>
                        {row.date !== null && (
                          <div className="mb-4 text-center text-sm">
                            {changeTimezone(
                              row.datetime,
                              filters.timezone,
                              "MM/DD/YY"
                            )}
                          </div>
                        )}
                        <div className="bot-msg-div  mb-4 flex items-end space-x-3">
                          <div
                            className="icon !pb-[18px]"
                            style={{ position: "unset" }}
                          >
                            {agent_image !== null ? (
                              <img
                                className="w-full"
                                src={agent_image.split("?")[0]}
                              />
                            ) : (
                              <img
                                className="w-full"
                                src={props.config.chat_logo}
                              />
                            )}
                          </div>
                          <div className="relative">
                            <div
                              className="message_text showFeedback relative bg-[#F1F1F1] py-4 px-5"
                              data-attr={`${row.turn_id}`}
                            >
                              {message !== "-NULL-" && message !== "" && (
                                <span
                                  data-attr={`${row.turn_id}`}
                                  className="showFeedback text-base font-normal text-black"
                                  dangerouslySetInnerHTML={{ __html: message }}
                                ></span>
                              )}
                              {row.content_title !== "" && (
                                <div className="block pt-4">
                                  <a
                                    href="#"
                                    className="text-bold m-text text-green-900"
                                    onClick={() => {
                                      if (
                                        row.iframe_id !== "" &&
                                        row.iframe_id !== null
                                      ) {
                                        setIframeContent(
                                          row.markup,
                                          row.link,
                                          row.content_title,
                                          row.iframe_id
                                        );
                                      } else {
                                        setMarkupContents(
                                          row.markup,
                                          row.content_title
                                        );
                                      }
                                    }}
                                  >
                                    {row.content_title}
                                  </a>
                                </div>
                              )}
                              <div className="absolute right-[20px] flex gap-[8px]">
                                {isRating && (
                                  <div className="flex gap-[8px]">
                                    {row.user_rating === "1" && (
                                      <span className="m-svg-color h-[32px] w-[32px] rounded-[50px] bg-white p-[3px]">
                                        <ThumbIcon />
                                      </span>
                                    )}
                                    {row.user_rating === "-1" && (
                                      <span className="m-svg-color h-[32px] w-[32px] rotate-180 rounded-[50px] bg-white p-[3px]">
                                        <ThumbIcon />
                                      </span>
                                    )}
                                    {row.user_feedback !== null &&
                                      row.user_feedback !== "" && (
                                        <div className="group relative">
                                          <div
                                            className="m-svg-color h-[32px] w-[32px] rounded-[50px] bg-white p-[3px]"
                                            onClick={() => {
                                              index === indexValue
                                                ? setIndexValue("")
                                                : setIndexValue(index);
                                            }}
                                          >
                                            <MessageIcon />
                                          </div>
                                          {index === indexValue && (
                                            <div
                                              className={`group${index} justify-left m-background m-background-opacity absolute inset-x-0 left-[-300px] z-50 flex w-[350px] items-end bg-green-900 p-4 text-lg text-white duration-100`}
                                            >
                                              {row.user_feedback}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                  </div>
                                )}
                                {isEvaluator &&
                                  selectedTurnid != row.turn_id && (
                                    <div className="flex gap-[8px]">
                                      {row.evaluator_rating === "1" && (
                                        <img
                                          className="showFeedbackicon h-[32px] w-[32px] rounded-[50px] bg-white p-[3px]"
                                          src={thumbIconEval}
                                        />
                                      )}
                                      {row.evaluator_rating === "-1" && (
                                        <img
                                          className="showFeedbackicon h-[32px] w-[32px] rotate-180 rounded-[50px] bg-white p-[3px]"
                                          src={thumbIconEval}
                                        />
                                      )}
                                      {row.evaluator_feedback != null &&
                                        row.evaluator_feedback != "" && (
                                          <div className="group relative ">
                                            <img
                                              className="showFeedbackicon h-[32px] w-[32px] rounded-[50px] bg-white p-[3px]"
                                              src={messageIconEval}
                                            />
                                          </div>
                                        )}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                          {row.time !== null && (
                            <div className="absolute right-1 bottom-[-15px] mb-4 text-xs text-black">
                              {changeTimezone(
                                row.datetime,
                                filters.timezone,
                                "HH:mm"
                              )}
                            </div>
                          )}
                        </div>
                        {selectedTurnid === row.turn_id && (
                          <Feedback
                            key={index}
                            submitFeedbackMessage={(
                              feedback,
                              turn_id,
                              conversation_id
                            ) =>
                              submitFeedback(
                                "",
                                turn_id,
                                conversation_id,
                                feedback,
                                "comment"
                              )
                            }
                            submitFeedback={(
                              rating,
                              turn_id,
                              conversation_id
                            ) =>
                              submitFeedback(
                                rating,
                                turn_id,
                                conversation_id,
                                "",
                                "rating"
                              )
                            }
                            row={row}
                          />
                        )}
                        {suggestion !== null && suggestion.length > 0 && (
                          <div className={`bot-msg`}>
                            <div className="bot-msg-div">
                              <div className="suggestion_div">
                                {suggestion.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="m-background m-2 ml-14 mr-12 rounded p-2 text-white"
                                    >
                                      {item}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  } else if (row.turn_actor === "system") {
                    var agent_image = null;
                    var agent_name = null;
                    if (message.includes("connected")) {
                      if (
                        row.attributes !== null &&
                        row.attributes !== undefined
                      ) {
                        var attributes = JSON.parse(row.attributes);
                        agent_image = attributes["image_url"];
                        agent_name = attributes["friendly_name"];
                      }
                    }
                    return (
                      <div className="!mb-10 max-w-md space-x-3">
                        <div className="relative">
                          <div className="user-message mr-[40px] p-3 py-4 px-5 text-center text-gray-700">
                            <span className="user-message text-sm font-normal">
                              {" "}
                              {message}
                            </span>
                          </div>
                          {agent_name && (
                            <div className="flex items-center justify-center gap-4">
                              {agent_image !== null && (
                                <div className="h-10 w-10 rounded-full">
                                  <img
                                    src={agent_image.split("?")[0]}
                                    className="center rounded-full"
                                  />
                                </div>
                              )}
                              <div>{agent_name}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  } else if (row.turn_actor === "other_events") {
                    return (
                      <div className="!mb-4 max-w-md space-x-3">
                        <div className="relative">
                          <div className="user-message mr-[40px] p-3 py-4 px-5 text-center text-gray-700">
                            <span className="user-message text-sm font-normal">
                              {" "}
                              {message}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="user-msg user-message ml-auto !mb-10 flex max-w-md justify-end space-x-3">
                        {row.date !== null && (
                          <div className="mb-4 text-center text-sm">
                            {changeTimezone(
                              row.datetime,
                              filters.timezone,
                              "MM/DD/YY"
                            )}
                          </div>
                        )}
                        <div className="relative">
                          <div className="message_text user-message m-background mr-[40px] rounded-l-[20px] rounded-tr-[20px] bg-green-900 p-3 py-4 px-5 text-white">
                            <span className="user-message text-right text-base font-normal">
                              {" "}
                              {message}
                            </span>
                          </div>
                          {row.time !== null && (
                            <div className="absolute right-1 bottom-0 mb-2 ml-2 text-xs text-black">
                              {changeTimezone(
                                row.datetime,
                                filters.timezone,
                                "HH:mm"
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }
                })}
              {/*<div className="absolute bg-[#F7F8FA] block w-full h-[76px] right-0 left-0 bottom-0 p-4 flex align-center justify-between gap-[11px]">
                <input className="placeholder:text-black px-[17px] py-[11px] w-full border border-solid border-[#DFE3E7] rounded-[100px] bg-white font-TimesNewRoman font-normal text-base text-black" placeholder="Type your message here..." />
                <button className="cursor-pointer">
                  <img src={sendIcon} />
                </button>
              </div>
            */}
              {userRating.user_rating != "" && (
                <div className="my-2 ml-auto flex w-full max-w-md space-x-3">
                  <span className="text-right text-base font-normal">
                    {" "}
                    User Rating: {userRating["user_rating"]}
                  </span>
                </div>
              )}
              {userRating.user_feedback != "" && (
                <div className="my-2 ml-auto flex w-full max-w-md space-x-3">
                  <span className="text-right text-base font-normal">
                    {" "}
                    User Feedback: {userRating["user_feedback"]}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        id="defaultModal"
        tabIndex="-1"
        aria-hidden="true"
        className={`fixed left-0 right-0 top-[60px] z-[999] h-[calc(100vh-5rem)] w-full overflow-y-auto overflow-x-hidden p-4 ${
          mobileMarkupText !== "" ? "block" : "hidden"
        }`}
      >
        <div className="relative h-[calc(100vh-1rem)] max-h-full w-full max-w-2xl">
          <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
            <div className="flex items-start justify-between rounded-t p-2 dark:border-gray-600">
              <button
                type="button"
                className="bg-transparent ml-auto inline-flex h-8 w-8 items-center justify-center rounded-lg text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="defaultModal"
                onClick={() => {
                  setMobileMarkupText("");
                }}
              >
                <svg
                  className="h-3 w-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div id="dynamicContentDiv" className="mt-[10px] max-w-[664px]">
              <ReactMarkdown
                children={mobileMarkupText}
                rehypePlugins={[rehypeRaw]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
