import React, { forwardRef, useState, useRef } from "react";
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

import { AiOutlinePlus } from "react-icons/ai";
import MultiListSearchText from "components/multiListSearchText/index.jsx";
import DateFilterSearch from "components/dateFilterSearch/index.jsx";
import NumericFilterSearch from "components/numericFilterSearch/index.jsx";
import CatgoryFilterSearch from "components/CatgoryFilterSearch/index.jsx";
import MultiChoiceSearchText from "components/multiChoiceSearchText/index.jsx";
import DropdownIndicator from "components/DropdownIndicator/DropdownIndicator";

import calendaricon from "assets/svg/calendar.svg";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import TopicsFilter from "components/topicsFilter";
import CustomCalenderIcon from "../../assets/svg/calenderIcon.svg";
import ClockIconImg from "../../assets/img/icons/clockIconImg.jpeg";
import { validateFromToDate } from "../../utils/utils";

import "./style.css";

function formattedLabel(label) {
  let splitLabel = label.split("_");
  let firstEle = splitLabel[0];
  let firstLater = firstEle.charAt(0).toUpperCase();
  let firstWord = firstLater + firstEle.substring(1);
  let newWordsArr = [firstWord, ...splitLabel.splice(1)];
  let newLabel = newWordsArr.join(" ");
  return newLabel;
}

const Filters = (props) => {
  // ------------for date time and dropdown start ----------

  const { startDate, endDate, startTime, endTime } = props.filters;
  const { merchantColors, isSelectedMerchant } = props;
  let { primaryColor, secondaryColor, tertiaryColor, seventhColor } =
    merchantColors;
  if (primaryColor == null) {
    primaryColor = "#6A1F70";
  }
  const [merchants, setMerchants] = useState([]);
  const [filterSelections, setFilterSelections] = useState([]);
  const [
    showOptionsToConfigureInFilterSelection,
    setShowOptionsToConfigureInFilterSelection,
  ] = useState(false);
  const [dateError, setDateError] = useState(false);

  const dispatch = useDispatch();
  const selectedMerchant = useSelector((state) => state.selectedValue);

  const filtersPageRef = useRef(null);

  const timezone = [
    { value: "+00:00", label: "UTC" },
    { value: "-08:00", label: "(GMT-08:00) Pacific Time (US & Canada)" },
    { value: "-05:00", label: "(GMT-05:00) Eastern Time (US & Canada)" },
    { value: "-06:00", label: "(GMT-06:00) Central Time (US & Canada)" },
    {
      value: "+05:30",
      label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    },
  ];

  const timezone_label = {
    "+00:00": "UTC",
    "-08:00": "(GMT-08:00) Pacific Time (US & Canada)",
    "-05:00": "(GMT-05:00) Eastern Time (US & Canada)",
    "-06:00": "(GMT-06:00) Central Time (US & Canada)",
    "+05:30": "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
  };

  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input w-full rounded border border-gray-200 py-2 font-poppins text-sm font-normal leading-7 text-secondaryColor"
      style={{ backgroundImage: `url({../../../assets/svg/calendar.svg})` }}
      onClick={onClick}
      ref={ref}
      onChange={() => {}}
    ></input>
  ));
  // ------------for date time and dropdown end ----------
  const checkboxFilterOptions = [
    // { id: 23, name: "topic", label:"topics", type:'category' },
    { id: 3, index: 0, name: "turnID", label: "Turns", type: "numeric" },
    {
      id: 4,
      index: 1,
      name: "convT2A",
      label: "Response Time",
      type: "numeric",
    },
    {
      id: 5,
      index: 2,
      name: "convOutcome",
      type: "category",
      label: "Outcome",
    },
    {
      id: 6,
      index: 3,
      name: "apptDate",
      type: "datetime",
      label: "Appointment Date",
    },
    // { id: 8, index: 4, name: "chatRating", type: 'numeric', label: "Chat Rating" },
    {
      id: 9,
      index: 5,
      name: "chatFeedback",
      type: "multilist",
      label: "User Feedback",
    },
    {
      id: 10,
      index: 6,
      name: "utm_source",
      type: "category",
      label: "UTM Source",
    },
    {
      id: 11,
      index: 7,
      name: "utm_medium",
      type: "category",
      label: "UTM medium",
    },
    {
      id: 12,
      index: 8,
      name: "utm_campaign",
      type: "category",
      label: "UTM campaign",
    },
    {
      id: 13,
      index: 9,
      name: "utm_placement",
      type: "category",
      label: "UTM placement",
    },
    {
      id: 14,
      index: 10,
      name: "utm_term",
      type: "category",
      label: "UTM term",
    },
    {
      id: 15,
      index: 11,
      name: "utm_content",
      type: "category",
      label: "UTM content",
    },
    {
      id: 16,
      index: 12,
      name: "utm_adplacement",
      type: "category",
      label: "UTM adplacement",
    },
    {
      id: 17,
      index: 13,
      name: "utm_match",
      type: "category",
      label: "UTM match",
    },
    {
      id: 19,
      index: 15,
      name: "utm_location",
      type: "category",
      label: "UTM location",
    },
    {
      id: 20,
      index: 16,
      name: "utm_target",
      type: "category",
      label: "UTM target",
    },
    {
      id: 21,
      index: 17,
      name: "gclid",
      type: "multilist",
      label: "AD Click ID",
    },
    {
      id: 22,
      index: 18,
      name: "device_type",
      type: "category",
      label: "Device",
    },
    {
      id: 1,
      index: 19,
      name: "conversation_id",
      label: "Conversation #",
      type: "multilist",
    },
    { id: 2, index: 20, name: "su_id", label: "User #", type: "multilist" },
    {
      id: 7,
      index: 21,
      name: "MarketoLead",
      type: "multilist",
      label: "Lead #",
    }, //***** */
    {
      id: 23,
      index: 22,
      name: "turn_rating",
      type: "numeric",
      label: "Turns with Rating",
    },
    {
      id: 24,
      index: 23,
      name: "turn_feedback",
      type: "numeric",
      label: "Turns with Feedback",
    },
  ];
  const [checkedList, setCheckedList] = useState(checkboxFilterOptions);
  const [removedOptionsFormCheckListObj, setRemovedOptionsFormCheckListObj] =
    useState({});

  const [showAdvancedFilters, setShowAdvancedFilters] = useState({
    topic: [],
    conversation_id: [],
    su_id: [],
    apptDate: { from_date: "", to_date: "", from_time: "", to_time: "" },
    turnID: {},
    convT2A: {},
    convOutcome: [],
    MarketoLead: [],
    chatRating: {},
    chatFeedback: [],
    utm_source: [],
    utm_medium: [],
    utm_campaign: [],
    utm_placement: [],
    utm_term: [],
    utm_content: [],
    utm_adplacement: [],
    utm_match: [],
    utm_location: [],
    utm_target: [],
    gclid: [],
    device_type: [],
    turn_rating: {},
    turn_feedback: {},
  });
  const [showFiltersOptions, setShowFiltersOptions] = useState([]);
  const [selectedOptionFromCheckList, setSelectedOptionFromCheckList] =
    useState("");
  const [filtersChoosen, setFilters] = useState([]);
  const [showAdvFiltersList, setShowAdvFiltersList] = useState(false);

  const [isRemoveSelectedOption, setIsRemoveSelectedOption] = useState(false);
  const [showAdvanceFilterOptions, setShowAdvanceFilterOptions] =
    useState(false);
  const [advanceSelectedFiltersInput, setAdvanceSelectedFiltersInput] =
    useState([]);
  const [filterSelectedToConfigure, setFilterSelectedToConfigure] =
    useState(null);

  useEffect(() => {
    const handleClick = async (e) => {
      let l_tmp = e.target;
      const parentWithClass = e.target.closest("div.dropdown-filters");
      const parentWithClass2 = e.target.closest("div.react-datepicker");
      if (
        parentWithClass === null &&
        !l_tmp.classList.contains("dropdown-filters") &&
        parentWithClass2 === null
      ) {
        toggleFilterOptionsDisplay(false);
        // hidefilterSelectionConfigration();
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    for (const filter in props.filters) {
      const indexOfFilter = checkboxFilterOptions.findIndex(
        (obj) => obj.name === filter
      );
      if (indexOfFilter != -1) {
        const yourVariable = props.filters[filter];
        if (
          (Array.isArray(yourVariable) && yourVariable.length > 0) ||
          (typeof yourVariable === "string" && yourVariable.trim() !== "") ||
          (typeof yourVariable === "object" &&
            Object.keys(yourVariable).length > 0 &&
            yourVariable[Object.keys(yourVariable)[0]] !== "")
        ) {
          // push only filter is empty this for more check  lk
          if (!filterSelections.length) {
            // filterSelections.push(checkboxFilterOptions[indexOfFilter]);
            setFilterSelections((preFilter) => [
              ...preFilter,
              checkboxFilterOptions[indexOfFilter],
            ]);
          }
          //   else if(filterSelections.filter((filterObj) => filterObj.id !== checkboxFilterOptions[indexOfFilter].id)){
          //     filterSelections.push(checkboxFilterOptions[indexOfFilter]);
          //   }
        }
      }
    }
  }, []);

  useEffect(() => {
    __init();
  }, [props.filterChange]);

  useEffect(() => {
    if (showAdvFiltersList && (filterSelectedToConfigure || filterSelections)) {
      setFilterSelectedToConfigure(null);
      // setFilterSelections(null)
      setShowOptionsToConfigureInFilterSelection(false);
    }
  }, [showAdvFiltersList]);

  useEffect(() => {
    if (props.resetState) {
      setFilterSelections((prevState) => {
        dispatch({
          type: "RESET_FILTERS",
          payload: {},
        });
        return [];
      });
    }
  }, [props.resetState]);

  const hidefilterSelectionConfigration = () => {
    unsetFilterSelectionConfigrationCheckedStatus();
    //  setFilterSelections([
    //     ...filterSelections.map((selection, idx) => {
    //         // selection.checked = false;
    //         return selection;
    //     })
    // ]);
  };

  const toggleFilterOptionsDisplay = (action) => {
    if (typeof action === "undefined") {
      setShowAdvFiltersList(!showAdvFiltersList);
      return;
    }
    setShowAdvFiltersList(action);
  };

  const __init = async () => {
    //   merchantOptions();
    //console.log("filters",props.filters)
    const {
      conversationId,
      suId,
      apptDate,
      turnID,
      convT2A,
      convOutcome,
      MarketoLead,
      chatRating,
      chatFeedback,
      device_type,
      turn_rating,
      turn_feedback,
    } = props.filters;
    const {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_placement,
      utm_term,
      utm_content,
      utm_adplacement,
      utm_match,
      utm_location,
      utm_target,
      gclid,
      topic,
    } = props.filters;
    var key_values = {};
    var show_filters = [];

    if (conversationId.length > 0) {
      let temp = conversationId.split(-6);
      key_values["conversation_id"] = conversationId;
      // key_values['conversation_id'] = temp;
      show_filters.push("conversation_id");
    }

    if (convOutcome.length > 0) {
      key_values["convOutcome"] = convOutcome;
      show_filters.push("convOutcome");
    }

    if (Object.keys(turn_rating).length > 0) {
      key_values["turn_rating"] = turn_rating;
      show_filters.push("turn_rating");
    }

    if (Object.keys(turn_feedback).length > 0) {
      key_values["turn_feedback"] = turn_feedback;
      show_filters.push("turn_feedback");
    }

    if (suId.length > 0) {
      key_values["su_id"] = suId;
      show_filters.push("su_id");
    }

    if (utm_source.length > 0) {
      key_values["utm_source"] = utm_source;
      show_filters.push("utm_source");
    }

    // if (topic?.length > 0) {
    //     key_values['topic'] = topic;
    //     show_filters.push('topic');
    // }

    if (utm_medium.length > 0) {
      key_values["utm_medium"] = utm_medium;
      show_filters.push("utm_medium");
    }

    if (utm_campaign.length > 0) {
      key_values["utm_campaign"] = utm_campaign;
      show_filters.push("utm_campaign");
    }

    if (utm_placement.length > 0) {
      key_values["utm_placement"] = utm_placement;
      show_filters.push("utm_placement");
    }

    if (utm_term.length > 0) {
      key_values["utm_term"] = utm_term;
      show_filters.push("utm_term");
    }

    if (utm_adplacement.length > 0) {
      key_values["utm_adplacement"] = utm_adplacement;
      show_filters.push("utm_adplacement");
    }

    if (utm_match.length > 0) {
      key_values["utm_match"] = utm_match;
      show_filters.push("utm_match");
    }

    if (utm_content.length > 0) {
      key_values["utm_content"] = utm_content;
      show_filters.push("utm_content");
    }

    if (utm_location.length > 0) {
      key_values["utm_location"] = utm_location;
      show_filters.push("utm_location");
    }

    if (utm_target.length > 0) {
      key_values["utm_target"] = utm_target;
      show_filters.push("utm_target");
    }

    if (gclid.length > 0) {
      key_values["gclid"] = gclid;
      show_filters.push("gclid");
    }

    if (device_type.length > 0) {
      key_values["device_type"] = device_type;
      show_filters.push("device_type");
    }

    if (MarketoLead.length > 0) {
      key_values["MarketoLead"] = MarketoLead;
      show_filters.push("MarketoLead");
    }

    if (Object.keys(turnID).length > 0) {
      key_values["turnID"] = turnID;
      show_filters.push("turnID");
    }

    if (Object.keys(convT2A).length > 0) {
      key_values["convT2A"] = convT2A;
      show_filters.push("convT2A");
    }

    if (Object.keys(chatRating).length > 0) {
      key_values["chatRating"] = chatRating;
      show_filters.push("chatRating");
    }

    if (Object.keys(chatFeedback).length > 0) {
      key_values["chatFeedback"] = chatFeedback;
      show_filters.push("chatFeedback");
    }

    if (apptDate["from"] !== "") {
      var start_date = apptDate["from"].split(" ");
      var end_date = apptDate["to"].split(" ");
      key_values["apptDate"] = {
        from_date: start_date[0],
        to_date: end_date[0],
        from_time: start_date[1],
        to_time: end_date[1],
      };
      show_filters.push("apptDate");
    }
    if (show_filters.length > 0) {
      await setShowFiltersOptions(show_filters);
      await setShowAdvancedFilters(key_values);
    }
  };

  const getFieldType = (field, label) => {
    var type = "";
    for (var i = 0; i < checkboxFilterOptions.length; i++) {
      if (checkboxFilterOptions[i].name === field) {
        type = checkboxFilterOptions[i][label];
      }
    }
    return type;
  };

  const unsetFilterByType = (type, fieldname) => {
    if (type == "multilist") {
      submitListValues([], fieldname);
    } else if (type == "numeric") {
      submitListValues({}, fieldname);
    } else if (type == "category") {
      submitListValues([], fieldname);
    } else if (type == "datetime") {
      submitListValues(
        { from_date: "", to_date: "", from_time: "", to_time: "" },
        fieldname
      );
    } else if (type == "multichoicelist") {
      submitListValues([], fieldname);
    }
  };

  const submitListValues = async (list, field_name) => {
    var values = [];
    values[field_name] = list;

    await setShowAdvancedFilters((prevValues) => {
      return { ...prevValues, ...values };
    });
    if (showFiltersOptions.indexOf(field_name) === -1) {
      await setShowFiltersOptions([...showFiltersOptions, field_name]);
    }
    if (field_name === "conversation_id") {
      await props.setFilters({ conversationId: list });
    }
    if (field_name === "su_id") {
      await props.setFilters({ suId: list });
    }
    if (field_name === "MarketoLead") {
      await props.setFilters({ MarketoLead: list });
    }
    if (field_name === "convOutcome") {
      await props.setFilters({ convOutcome: list });
    }
    if (field_name === "turn_rating") {
      await props.setFilters({ turn_rating: list });
    }
    if (field_name === "turn_feedback") {
      await props.setFilters({ turn_feedback: list });
    }
    if (field_name === "turnID") {
      await props.setFilters({ turnID: list });
    }
    if (field_name === "convT2A") {
      await props.setFilters({ convT2A: list });
    }
    if (field_name === "chatRating") {
      await props.setFilters({ chatRating: list });
    }
    if (field_name === "chatFeedback") {
      await props.setFilters({ chatFeedback: list });
    }
    if (field_name === "utm_source") {
      await props.setFilters({ utm_source: list });
    }
    // if (field_name === "topic") {
    //     await props.setFilters({topic: list})
    // }
    if (field_name === "utm_medium") {
      await props.setFilters({ utm_medium: list });
    }
    if (field_name === "utm_campaign") {
      await props.setFilters({ utm_campaign: list });
    }
    if (field_name === "utm_placement") {
      await props.setFilters({ utm_placement: list });
    }
    if (field_name === "utm_term") {
      await props.setFilters({ utm_term: list });
    }
    if (field_name === "utm_content") {
      await props.setFilters({ utm_content: list });
    }
    if (field_name === "utm_adplacement") {
      await props.setFilters({ utm_adplacement: list });
    }
    if (field_name === "utm_match") {
      await props.setFilters({ utm_match: list });
    }
    if (field_name === "utm_location") {
      await props.setFilters({ utm_location: list });
    }
    if (field_name === "utm_target") {
      await props.setFilters({ utm_target: list });
    }
    if (field_name === "gclid") {
      await props.setFilters({ gclid: list });
    }
    if (field_name === "device_type") {
      await props.setFilters({ device_type: list });
    }
    if (field_name === "apptDate") {
      var fromdate = "";

      if (list.from_date !== "") {
        fromdate = list.from_date + " " + list.from_time;
      }
      var todate = "";
      if (list.to_date !== "") {
        todate = list.to_date + " " + list.to_time;
      }
      var date = { from: fromdate, to: todate };
      await props.setFilters({ apptDate: date });
    }
    setFilterOptionsShow(false);
  };

  const getTopicFilterSelectionToDisplay = ({
    id,
    name,
    label,
    type,
    backgroundColor,
    textColor,
  }) => {
    if (name in showAdvancedFilters) {
      if (type === "numeric") {
        const filtersStrings = [];
        const selectedFilterConfigrationByName = showAdvancedFilters[name];
        for (const filterType in selectedFilterConfigrationByName) {
          const filterValue = selectedFilterConfigrationByName[filterType];
          filtersStrings.push(filterType + " " + filterValue);
        }
        return `<p style="font-weight:500">${label} <span style="color:${textColor};">${filtersStrings.join(
          " or "
        )}</span></p>`;
      } else if (type === "category" || type == "multilist") {
        const selectedFilterConfigrationByName = showAdvancedFilters[name];
        return `<p style="font-weight:500">${label} <span style="color: ${textColor};">${selectedFilterConfigrationByName.join(
          ", "
        )}</span></p>`;
      } else if (type === "datetime") {
        const selectedFilterConfigrationByName = showAdvancedFilters[name];
        return `<p style="font-weight:500">${label} <span style="color: ${textColor};">From: ${selectedFilterConfigrationByName["from_date"]} ${selectedFilterConfigrationByName["from_time"]}</span><span style="color: ${textColor};">  To: ${selectedFilterConfigrationByName["to_date"]} ${selectedFilterConfigrationByName["to_time"]}</span></p>`;
      } else if (type === "multilist") {
        const selectedFilterConfigrationByName = showAdvancedFilters[name];
        return `<p style="font-weight:500">${label} <span style="color: ${textColor};">${selectedFilterConfigrationByName.join(
          ", "
        )}</span></p>`;
      }
    }
    return `<p>label</p>`;
  };

  function getRangesValue(props) {
    // const ranges = ['less_than', 'less_than_equal', 'greater_than', 'greater_than_equal', 'between']
    // let advanceFiltersInput = [];
    // for (const key in props) {
    //     let label = formattedLabel(key)
    //     // if(key === 'less_than'){
    //     //     label = 'Less than'
    //     // }else if(key === 'less_than_equal'){
    //     // }
    //     advanceFiltersInput.push({ field: key, value: props[key], label })
    // }
    // setAdvanceSelectedFiltersInput([...advanceSelectedFiltersInput, ...advanceFiltersInput ])
  }

  const handleFilterSelection = (id) => {
    // check if this filter is already added
    const findFilterIdIndex = filterSelections.findIndex((obj) => obj.id == id);
    if (findFilterIdIndex != -1) {
      toggleFilterOptionsDisplay();
      return;
    }
    // // if not just add it
    const selectedOption = checkedList.filter((list) => list.id === id)[0];
    // setFilterSelections([
    //     ...filterSelections,
    //     selectedOption,
    // ])
    setFilterSelectedToConfigure((prevSelection) => {
      return selectedOption;
    });
    toggleFilterOptionsDisplay();
  };
  const checkboxClicked = (id) => {
    setCheckedList((prevCheckedList) => {
      const toSet = prevCheckedList.map((checkedItem) => {
        if (checkedItem.id === id) {
          checkedItem = { ...checkedItem, checked: !checkedItem.checked };
        } else {
          checkedItem = { ...checkedItem, checked: false };
        }
        return checkedItem;
      });
      return toSet;
    });

    setIsRemoveSelectedOption(true);
    const selectedOption = checkedList.filter((list) => list.id === id)[0];
    setSelectedOptionFromCheckList(selectedOption);
  };

  const showError = () => {
    setDateError(true);
    //   setTimeout(() => {setDateError(false)}, 2000)
  };

  const merchantOptions = async () => {
    var token = Cookies.get("token");
    fetch(`${process.env.REACT_APP_APIURL}/merchants_list`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const uniqueArray = data[0]["record"];
        var list = [];
        uniqueArray.map((item, index) => {
          list.push({ value: item.name, label: item.name });
        });
        setMerchants(list);
      })
      .catch((error) => {
        //console.error(error);
      });
  };

  const resetFilterValue = async (field_index, field_type, field_name) => {
    if (
      field_type === "multilist" ||
      field_type === "category" ||
      field_type === "multichoicelist"
    ) {
      var values = [];
      var newValues = showAdvancedFilters[field_name];
      newValues.splice(field_index, 1);
      values[field_name] = newValues;
      setShowAdvancedFilters((prevValues) => {
        return { ...prevValues, ...values };
      });
      if (newValues.length === 0) {
        var showFilters = showFiltersOptions;
        for (var i = showFilters.length - 1; i >= 0; i--) {
          if (showFilters[i] === field_name) {
            showFilters.splice(i, 1);
          }
        }
        setShowFiltersOptions(showFilters);
      }
      props.setFilters({ field_name: newValues });
    } else if (field_type === "numeric") {
      var values = [];
      values[field_name] = showAdvancedFilters[field_name];
      delete values[field_name][field_index];
      await setShowAdvancedFilters((prevValues) => {
        return { ...prevValues, ...values };
      });
      var showFilters = showFiltersOptions;
      for (var i = showFilters.length - 1; i >= 0; i--) {
        if (showFilters[i] === field_name) {
          showFilters.splice(i, 1);
        }
      }
      await setShowFiltersOptions(showFilters);
      props.setFilters(values);
    } else if (field_type === "datetime") {
      var values = [];
      values[field_name] = {
        from_date: "",
        to_date: "",
        from_time: "",
        to_time: "",
      };
      setShowAdvancedFilters((prevValues) => {
        return { ...prevValues, ...values };
      });
      var showFilters = showFiltersOptions;
      for (var i = showFilters.length - 1; i >= 0; i--) {
        if (showFilters[i] === field_name) {
          showFilters.splice(i, 1);
        }
      }
      setShowFiltersOptions(showFilters);
      values[field_name] = { from: "", to: "" };
      props.setFilters(values);
    }
  };

  const handleChangeMerchant = (selected) => {
    props.setFilters({ merchant: selected });
    let array = [];
    selected.forEach((item, index) => {
      array.push(item["value"]);
    });
    if (array.length === 1) {
      dispatch({ type: "SET_SELECTED_VALUE", payload: array[0] });
    } else {
      dispatch({ type: "SET_SELECTED_VALUE", payload: "All" });
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            className="multiple_option mr-2"
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const unsetFilterSelectionConfigrationCheckedStatus = () => {
    setFilterSelections([
      ...filterSelections.map((selection, idx) => {
        selection.checked = false;
        return selection;
      }),
    ]);
  };

  // ------------nested checkboxes end---------
  // ---------add filter button toogle start----------
  const [filterOptionsShow, setFilterOptionsShow] = useState(false);
  // ---------add filter button toogle end----------

  const renderKeyValuePairs = (obj, field_name) => {
    return Object.entries(obj).map(([key, value]) => {
      if (key === "between") {
        return (
          <div
            key={key}
            className="group relative flex gap-4 rounded border border-gray-400 p-1"
          >
            <button
              className="absolute -right-2 -top-2 hidden group-hover:block"
              onClick={() => resetFilterValue(key, "numeric", field_name)}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5 7.56885C15.5 11.7111 12.1423 15.0688 8 15.0688C3.85775 15.0688 0.5 11.7111 0.5 7.56885C0.5 3.4266 3.85775 0.0688477 8 0.0688477C12.1423 0.0688477 15.5 3.4266 15.5 7.56885ZM5.7275 5.29635C5.83297 5.19101 5.97594 5.13184 6.125 5.13184C6.27406 5.13184 6.41703 5.19101 6.5225 5.29635L8 6.77385L9.4775 5.29635C9.58413 5.19699 9.72517 5.14289 9.87089 5.14547C10.0166 5.14804 10.1557 5.20707 10.2587 5.31013C10.3618 5.41319 10.4208 5.55223 10.4234 5.69796C10.426 5.84368 10.3719 5.98472 10.2725 6.09135L8.795 7.56885L10.2725 9.04635C10.3719 9.15298 10.426 9.29401 10.4234 9.43974C10.4208 9.58547 10.3618 9.7245 10.2587 9.82756C10.1557 9.93062 10.0166 9.98966 9.87089 9.99223C9.72517 9.9948 9.58413 9.94071 9.4775 9.84135L8 8.36385L6.5225 9.84135C6.41587 9.94071 6.27483 9.9948 6.12911 9.99223C5.98338 9.98966 5.84434 9.93062 5.74128 9.82756C5.63822 9.7245 5.57919 9.58547 5.57662 9.43974C5.57405 9.29401 5.62814 9.15298 5.7275 9.04635L7.205 7.56885L5.7275 6.09135C5.62216 5.98588 5.56299 5.84291 5.56299 5.69385C5.56299 5.54478 5.62216 5.40182 5.7275 5.29635Z"
                  fill="rgb(174,83,65)"
                />
              </svg>
            </button>
            <div className="p-1">{key}</div>
            <div className="w-[50px] rounded border border-gray-400 p-1 text-right">
              {value[0]}
            </div>
            <div className="w-[50px] rounded border border-gray-400 p-1 text-right">
              {value[1]}
            </div>
          </div>
        );
      } else {
        return (
          <div
            key={key}
            className="group relative flex gap-4 rounded border border-gray-400 p-1"
          >
            <button
              className="absolute -right-2 -top-2 hidden group-hover:block"
              onClick={() => resetFilterValue(key, "numeric", field_name)}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5 7.56885C15.5 11.7111 12.1423 15.0688 8 15.0688C3.85775 15.0688 0.5 11.7111 0.5 7.56885C0.5 3.4266 3.85775 0.0688477 8 0.0688477C12.1423 0.0688477 15.5 3.4266 15.5 7.56885ZM5.7275 5.29635C5.83297 5.19101 5.97594 5.13184 6.125 5.13184C6.27406 5.13184 6.41703 5.19101 6.5225 5.29635L8 6.77385L9.4775 5.29635C9.58413 5.19699 9.72517 5.14289 9.87089 5.14547C10.0166 5.14804 10.1557 5.20707 10.2587 5.31013C10.3618 5.41319 10.4208 5.55223 10.4234 5.69796C10.426 5.84368 10.3719 5.98472 10.2725 6.09135L8.795 7.56885L10.2725 9.04635C10.3719 9.15298 10.426 9.29401 10.4234 9.43974C10.4208 9.58547 10.3618 9.7245 10.2587 9.82756C10.1557 9.93062 10.0166 9.98966 9.87089 9.99223C9.72517 9.9948 9.58413 9.94071 9.4775 9.84135L8 8.36385L6.5225 9.84135C6.41587 9.94071 6.27483 9.9948 6.12911 9.99223C5.98338 9.98966 5.84434 9.93062 5.74128 9.82756C5.63822 9.7245 5.57919 9.58547 5.57662 9.43974C5.57405 9.29401 5.62814 9.15298 5.7275 9.04635L7.205 7.56885L5.7275 6.09135C5.62216 5.98588 5.56299 5.84291 5.56299 5.69385C5.56299 5.54478 5.62216 5.40182 5.7275 5.29635Z"
                  fill="rgb(174,83,65)"
                />
              </svg>
            </button>
            <div className="p-1">{key}</div>
            <div className="w-[50px] rounded border border-gray-400 p-1 text-right">
              {value}
            </div>
          </div>
        );
      }
    });
  };

  function resetCheckedList(removedFilter) {
    const removedFilterId = removedFilter.id;
    const pickOptionFormRemovedCheckList =
      removedOptionsFormCheckListObj[removedFilterId];

    const indexToAdd = pickOptionFormRemovedCheckList.index;
    checkedList.splice(indexToAdd, 0, pickOptionFormRemovedCheckList);

    if (removedOptionsFormCheckListObj[removedFilterId]) {
      delete removedOptionsFormCheckListObj[removedFilterId];
    }
  }

  useEffect(() => {
    // Case1   // filterSelections[] has the all the selected value filter{},
    // if filters[name] === checkList[{}][name]
    // then remove that filter from the checkList
    // Case2   // if any filters has been removed from filterSelections[]
    // so that filter should be added to again in checkList[]

    // let selectedFiltersMap = {};
    // let filterSelectionOptionsMap = {};
    let removedOptionsFormCheckListMap = {};
    // for (let selectedFilter of filterSelections){
    //     if (!selectedFiltersMap[selectedFilter.id]){
    //         selectedFiltersMap[selectedFilter.id] = selectedFilter;
    //     }
    // }

    // for (let filter of checkedList) {
    //     if (!filterSelectionOptionsMap[filter.id]) {
    //         filterSelectionOptionsMap[filter.id] = filter;
    //     }
    // }
    // //console.log("filterSelections", filterSelections, selectedFiltersMap, filterSelectedToConfigure, checkedList, filterSelectionOptionsMap)

    // let newCheckedList;
    // for (let id in selectedFiltersMap){

    //     if (id in filterSelectionOptionsMap){
    //          //console.log("filterSelections",id, filterSelectionOptionsMap[id])
    //         const index = checkedList.indexOf(filterSelectionOptionsMap[id].id);
    //         // newCheckedList = checkedList.filter((filterOption, index) => filterOption.id !== id.id)

    //         // store the currently removed filter from the checklist to retrieve it when again add it's into checkedList or removed from filterSelections
    //         if(!removedOptionsFormCheckListMap[index]){
    //             removedOptionsFormCheckListMap[index] = removedOptionsFormCheckListMap[id]
    //         }
    //         if (index>-1){
    //           newCheckedList = checkedList.splice(index, 1)
    //         }

    //      //console.log("filterSelections newCheckedList", newCheckedList, id, index, removedOptionsFormCheckListMap)
    //     }
    // }
    // setCheckedList([...newCheckedList])

    // let newList  = checkedList.reduce((acc, curr, index) => {
    //  if(filterSelections.find((selectedFilter, index)=> selectedFilter.id === curr.id)){
    //     removedOptionsFormCheckListMap[index] = checkedList[index];
    //     // return;
    //  }else{

    //     // acc.push(curr)
    //     //console.log("filterSelections",acc, index)
    //     return [...acc, curr];
    //  }
    // }, [])
    // //console.log("filterSelections newCheckedList", newList, removedOptionsFormCheckListMap)

    const newCheckedList = checkedList.filter((_checked, index) => {
      return !filterSelections.find((_filter) => {
        if (_filter.id === _checked.id) {
          removedOptionsFormCheckListMap[_checked.id] = _checked;
          return _filter.id === _checked.id;
        }
      });
    });

    setCheckedList([...newCheckedList]);
    setRemovedOptionsFormCheckListObj((prevObj) => {
      return { ...prevObj, ...removedOptionsFormCheckListMap };
    });
  }, [filterSelections]);

  useEffect(() => {
    if (props.resetState) {
      setCheckedList(checkboxFilterOptions);
    }
  }, [props.resetState]);

  const colorStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor:
          (isSelected || isFocused) && primaryColor
            ? primaryColor
            : "transparent",
        color:
          (isSelected || isFocused) && primaryColor ? "#FFFFFF" : "#000000",
        // width: '400px',
      };
    },
  };

  return (
    <div className="filters" ref={filtersPageRef}>
      {dateError && (
        <div className="pb-6 text-red-500">
          Please select valid start date and end date
        </div>
      )}
      <div className="md:flex md:gap-8">
        {/* <div>
                    <p className="font-poppins font-medium text-sm text-secondaryColor pb-1">Timezone
                    </p>
                    <div className="flex gap-2">
                        <label>
                        <ReactSelect
                            options={timezone}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                                Option
                            }}
                            onChange={(selected) => {props.setFilters({ timezone: selected.value })}}
                            allowSelectAll={true}
                            value={{"label":timezone_label[props.filters.timezone],"value":props.filters.timezone}}
                            placeholder="All"
                            className="w-[300px]"
                            />
                        </label>
                    </div> ̰
                </div> */}
        <div className="z-30 py-4 md:py-0">
          <p className="pb-1 font-poppins text-sm font-medium text-secondaryColor">
            From
          </p>
          {/* <BsFillCalendarCheckFill /> */}
          <div className="gap-2 md:flex">
            <label>
              <div className="DateContainer customDatePickerColor flex w-full rounded border py-[6px]">
                <span
                  // ${selectedMerchant === 'clover' ? 'clover' : selectedMerchant === 'vurbalize' ? 'vurbalize' : selectedMerchant === 'lightspeedhq' ? 'lightspeedhq' : selectedMerchant === 'mytonomy' ? 'payactiv' : selectedMerchant === 'payactiv' ? 'payactiv' :'All'}
                  className={` d dateInput z-20`}
                >
                  <DatePicker
                    showIcon={false}
                    style={{
                      backgroundColor: "aliceblue",
                      height: "24px",
                      borderRadius: "8px",
                      fontSize: "14px",
                      padding: "3px 10px",
                    }}
                    selected={startDate}
                    onChange={(date) => {
                      setDateError(false);
                      var status = validateFromToDate(
                        date,
                        endDate,
                        startTime,
                        endTime
                      );
                      if (status === true) {
                        showError();
                        return;
                      }
                      props.setFilters({ startDate: date, startTime: "00:00" });
                    }}
                    // dayClassName={() => "example-datepicker-day-class"}
                    // popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    wrapperClassName="datePicker"
                    // customInput={<CustomInput />}
                  />
                </span>
                <span className="icon">
                  <img src={CustomCalenderIcon} />
                </span>
              </div>
            </label>
            <div className="relative">
              <TimePicker
                clockIcon
                onChange={(time) => {
                  setDateError(false);
                  var status = validateFromToDate(
                    startDate,
                    endDate,
                    time,
                    endTime
                  );
                  if (status === true) {
                    showError();
                    return;
                  }
                  props.setFilters({ startTime: time });
                }}
                value={startTime || "10:00 AM"}
                // isOpen={true}
                className="w-[9rem] rounded border border-gray-200 bg-white py-1 pl-2 font-poppins text-sm font-normal leading-7 text-secondaryColor"
              />
              <span className="absolute top-[12px] right-[-6px] w-[30px]">
                <img
                  src={ClockIconImg}
                  className="h-[1rem] w-[1rem]"
                  alt="icon"
                />
              </span>
            </div>
          </div>
        </div>
        <div className="z-40 py-4 md:py-0">
          <p className="pb-1 font-poppins text-sm font-medium text-secondaryColor">
            To
          </p>
          {/* <BsFillCalendarCheckFill /> */}
          <div className="flex gap-2">
            <label>
              <div className="DateContainer customDatePickerColor flex w-full rounded border py-[6px]">
                <span
                  // ${selectedMerchant === 'clover' ? 'clover' : selectedMerchant === 'vurbalize' ? 'vurbalize' : selectedMerchant === 'lightspeedhq' ? 'lightspeedhq' : selectedMerchant === 'mytonomy' ? 'payactiv' : selectedMerchant === 'payactiv' ? 'payactiv' :'All'}
                  className={` d dateInput z-20`}
                >
                  <DatePicker
                    showIcon={false}
                    selected={endDate}
                    onChange={(date) => {
                      setDateError(false);
                      var status = validateFromToDate(
                        startDate,
                        date,
                        startTime,
                        endTime
                      );
                      if (status === true) {
                        showError();
                        return;
                      }
                      props.setFilters({ endDate: date, endTime: "23:59" });
                    }}
                    // customInput={<CustomInput />}
                    wrapperClassName="datePicker"
                  />
                </span>
                <span className="icon">
                  <img src={CustomCalenderIcon} />
                </span>
              </div>
            </label>
            <div className="relative">
              <TimePicker
                clockIcon
                onChange={(time) => {
                  setDateError(false);
                  var status = validateFromToDate(
                    startDate,
                    endDate,
                    startTime,
                    time
                  );
                  if (status === true) {
                    showError();
                    return;
                  }
                  props.setFilters({ endTime: time });
                }}
                value={endTime || "10:00 AM"}
                className="w-[9rem] rounded border border-gray-200  bg-white py-1 pl-2 font-poppins text-sm font-normal leading-7 text-secondaryColor"
              />
              <span className="absolute top-[12px] right-[-6px] w-[30px]">
                <img
                  src={ClockIconImg}
                  className="h-[1rem] w-[1rem]"
                  alt="icon"
                />
              </span>
            </div>
          </div>
        </div>

        <div className="z-40 w-1/2 py-4 md:basis-1/2 md:py-0">
          <p className="font-poppins text-sm font-medium text-secondaryColor">
            Pages
          </p>
          <div className="flex gap-2 pt-1">
            <label className="selectionOptionCustomWidth">
              <ReactSelect
                options={props.landingPage}
                isMulti
                // menuIsOpen={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option,
                  DropdownIndicator,
                }}
                onChange={(value) => {
                  props.setFilters({ landingpage: value });
                }}
                allowSelectAll={true}
                value={props.filters.landingpage}
                placeholder="All"
                className="w-[20rem]"
                styles={colorStyles}
              />
            </label>
          </div>
        </div>
        {/* Uncomment this upper block if needed to reverse this change */}
      </div>

      {/* <div className="py-4 w-full md:flex gap-8">
            {merchants.length > 1 &&
                <div>
              <p className="font-poppins font-medium text-sm text-secondaryColor">Merchants </p>
                <ReactSelect
                    options={merchants}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                        Option
                    }}
                    onChange={(value) => handleChangeMerchant(value)}
                    allowSelectAll={true}
                    value={props.filters.merchant}
                    placeholder="All"
                    className="w-[300px]"
                    />
                    </div>
                   
            }
             <div className="w-full py-4 md:py-0 md:basis-1/2">
                    <p className="font-poppins font-medium text-sm text-secondaryColor">Landing Pages
                    </p>
                    <ReactSelect
                    options={props.landingPage}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                        Option
                    }}
                    onChange={(value) => {props.setFilters({ landingpage: value })}}
                    allowSelectAll={true}
                    value={props.filters.landingpage}
                    placeholder="All"
                    />
                </div>
            </div> */}
      <div className="gap-3 md:flex">
        <h4 className="relative mb-4 py-2 font-poppins text-base font-medium text-secondaryColor">
          Advanced filters
        </h4>
        {/* <div className="flex"> */}
        <div className="relative">
          <button
            onClick={() => {
              // setFilterOptionsShow(!filterOptionsShow)
              // setShowAdvanceFilterOptions(false)
              // setIsRemoveSelectedOption(false)
              // setAdvanceSelectedFiltersInput([])
              toggleFilterOptionsDisplay();
            }}
            className="dropdown-filters flex h-10 w-36 items-center justify-center gap-2.5 rounded py-1.5 pr-2 pl-3 font-poppins font-normal font-medium text-[#6A1F70]"
            style={{ color: primaryColor }}
          >
            <AiOutlinePlus />
            {/* change bg color */}
            Add Filters
          </button>

          {showAdvFiltersList && (
            <div className="top-13 dropdown-filters absolute z-10 z-30 w-60 rounded border border-[#ECECEC] bg-white font-poppins text-base font-normal text-[#1B1B1D] shadow-[#000000]">
              <div className="dropdown-filters flex flex-col">
                {checkedList.map(({ id, name, checked, label }) => (
                  <label
                    className="m-background-hover cursor-pointer px-4 py-3"
                    // htmlFor={id}
                    // className={`dropdown-filters checkboxFilterOption relative cursor-pointer ${checked ? "checkboxFilter" : ""
                    //     }`}
                    // key={id}
                    onClick={() => {
                      handleFilterSelection(id);
                      filtersPageRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                        inline: "nearest",
                      });
                      // checkboxClicked(id)
                      // setFilterOptionsShow(!filterOptionsShow)
                    }}
                  >
                    {/* <input
                                    type="checkbox"
                                    name={id}
                                    id={id}
                                    value={id}
                                    checked={checked}
                                    onChange={() => checkboxClicked(id)}
                                    checked={checked}
                                /> */}
                    {label}
                  </label>
                ))}
              </div>
            </div>
          )}

          {/* </div> */}
        </div>
      </div>

      <div className="f-full flex w-full gap-2">
        {/* DIV FOR DISPLAYING FILTERS */}
        {filterSelections &&
          filterSelections.map((selection, index) => {
            return (
              <div className="" key={index}>
                <div
                  id="existing-filters"
                  className="relative left-0 mr-2 flex  flex-row rounded border-2 px-3 py-2 font-poppins text-sm font-normal text-[#1B1B1D]"
                  onClick={async () => {
                    // setFilterSelectedToConfigure(selection);
                    setShowOptionsToConfigureInFilterSelection(true);
                    setFilterSelectedToConfigure(null);
                    // checked - true in the index
                    await setFilterSelections([
                      ...filterSelections.map((selection, idx) => {
                        if (index === idx) {
                          selection.checked = !selection.checked;
                        } else {
                          selection.checked = false;
                        }

                        return selection;
                      }),
                    ]);
                  }}
                  style={{
                    borderColor: primaryColor ? primaryColor : "#6A1F70",
                    background:
                      seventhColor && seventhColor !== "#ffffff"
                        ? seventhColor
                        : "#FBEFFC",
                    // background: seventhColor ? seventhColor : !isSelectedMerchant ? '#FBEFFC' : '#ffffff',
                    // borderColor: primaryColor ? primaryColor : !isSelectedMerchant ? '#6A1F70' : '#ffffff',
                    minWidth: selection.name === "apptDate" && "430px",
                  }}
                >
                  {/* this is the cross button */}
                  <button
                    className="absolute -right-2 -top-2 rounded-full bg-white"
                    onClick={(e) => {
                      e.stopPropagation();
                      // delete the selection from the selected options
                      setFilterSelections([
                        ...filterSelections.slice(0, index),
                        ...filterSelections.slice(index + 1),
                      ]);
                      resetCheckedList(selection, index);
                      unsetFilterByType(selection.type, selection.name);
                      // remove from the filters as well if present
                      // props.setFilters()
                      //    setIsRemoveSelectedOption(!isRemoveSelectedOption)
                    }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.5 7.56885C15.5 11.7111 12.1423 15.0688 8 15.0688C3.85775 15.0688 0.5 11.7111 0.5 7.56885C0.5 3.4266 3.85775 0.0688477 8 0.0688477C12.1423 0.0688477 15.5 3.4266 15.5 7.56885ZM5.7275 5.29635C5.83297 5.19101 5.97594 5.13184 6.125 5.13184C6.27406 5.13184 6.41703 5.19101 6.5225 5.29635L8 6.77385L9.4775 5.29635C9.58413 5.19699 9.72517 5.14289 9.87089 5.14547C10.0166 5.14804 10.1557 5.20707 10.2587 5.31013C10.3618 5.41319 10.4208 5.55223 10.4234 5.69796C10.426 5.84368 10.3719 5.98472 10.2725 6.09135L8.795 7.56885L10.2725 9.04635C10.3719 9.15298 10.426 9.29401 10.4234 9.43974C10.4208 9.58547 10.3618 9.7245 10.2587 9.82756C10.1557 9.93062 10.0166 9.98966 9.87089 9.99223C9.72517 9.9948 9.58413 9.94071 9.4775 9.84135L8 8.36385L6.5225 9.84135C6.41587 9.94071 6.27483 9.9948 6.12911 9.99223C5.98338 9.98966 5.84434 9.93062 5.74128 9.82756C5.63822 9.7245 5.57919 9.58547 5.57662 9.43974C5.57405 9.29401 5.62814 9.15298 5.7275 9.04635L7.205 7.56885L5.7275 6.09135C5.62216 5.98588 5.56299 5.84291 5.56299 5.69385C5.56299 5.54478 5.62216 5.40182 5.7275 5.29635Z"
                        fill="rgb(174,83,65)"
                      />
                    </svg>
                  </button>
                  {/* RENDER IN THE FILTERS SELECTED TILES */}
                  {
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getTopicFilterSelectionToDisplay({
                          ...selection,
                          textColor: primaryColor,
                        }),
                      }}
                    ></div>
                  }
                  {/* {selection.name} */}
                </div>
                <div className="relative -left-2  z-40">
                  {showOptionsToConfigureInFilterSelection
                    ? filterSelections.map(
                        ({ id, name, checked, type }, index2) => {
                          if (
                            index == index2 &&
                            checked &&
                            type === "multilist"
                          ) {
                            return (
                              <div className="absolute top-3 left-0">
                                <MultiListSearchText
                                  field_name={name}
                                  id={props.id}
                                  submitListValues={(list, field_name) =>
                                    submitListValues(list, field_name)
                                  }
                                  currentValues={
                                    showAdvancedFilters[name] !== undefined
                                      ? showAdvancedFilters[name]
                                      : []
                                  }
                                  closePopup={() => {
                                    // checkboxClicked(id)
                                    unsetFilterSelectionConfigrationCheckedStatus();
                                  }}
                                />
                              </div>
                            );
                          } else if (
                            index == index2 &&
                            checked &&
                            type === "datetime"
                          ) {
                            return (
                              <div className="absolute top-3 left-0">
                                <DateFilterSearch
                                  field_name={name}
                                  id={props.id}
                                  submitListValues={(list, field_name) => {
                                    submitListValues(list, field_name);
                                    unsetFilterSelectionConfigrationCheckedStatus();
                                  }}
                                  currentValues={
                                    showAdvancedFilters[name] !== undefined
                                      ? showAdvancedFilters[name]
                                      : {
                                          from_date: "",
                                          to_date: "",
                                          from_time: "",
                                          to_time: "",
                                        }
                                  }
                                  closePopup={() => {
                                    unsetFilterSelectionConfigrationCheckedStatus();
                                    // checkboxClicked(id)
                                  }}
                                />
                              </div>
                            );
                          } else if (
                            index == index2 &&
                            checked &&
                            type === "numeric"
                          ) {
                            return (
                              // <h1>checked:{checked ? "true": "false"}&type:{type}</h1>
                              <div className="absolute top-3 left-0">
                                <NumericFilterSearch
                                  field_name={name}
                                  id={props.id}
                                  submitListValues={(list, field_name) => {
                                    submitListValues(list, field_name);
                                    unsetFilterSelectionConfigrationCheckedStatus();
                                  }}
                                  currentValues={
                                    showAdvancedFilters[name] !== undefined
                                      ? showAdvancedFilters[name]
                                      : {}
                                  }
                                  closePopup={() => {
                                    // checkboxClicked(id)
                                    unsetFilterSelectionConfigrationCheckedStatus();
                                  }}
                                  getRangesValue={getRangesValue}
                                />
                              </div>
                            );
                          } else if (
                            index == index2 &&
                            checked &&
                            type === "category"
                          ) {
                            return (
                              <div className="absolute top-3 left-0">
                                <CatgoryFilterSearch
                                  field_name={name}
                                  id={props.id}
                                  submitListValues={(list, field_name) => {
                                    submitListValues(list, field_name);
                                    unsetFilterSelectionConfigrationCheckedStatus();
                                  }}
                                  currentValues={
                                    showAdvancedFilters[name] !== undefined
                                      ? showAdvancedFilters[name]
                                      : {}
                                  }
                                  closePopup={() => {
                                    // checkboxClicked(id)
                                    unsetFilterSelectionConfigrationCheckedStatus();
                                  }}
                                  filters={props.filters}
                                />
                              </div>
                            );
                          } else if (
                            index == index2 &&
                            checked &&
                            type === "multichoicelist"
                          ) {
                            return (
                              <div className="absolute top-3 left-0">
                                <MultiChoiceSearchText
                                  field_name={name}
                                  id={props.id}
                                  submitListValues={(list, field_name) => {
                                    submitListValues(list, field_name);
                                    unsetFilterSelectionConfigrationCheckedStatus();
                                  }}
                                  currentValues={
                                    showAdvancedFilters[name] !== undefined
                                      ? showAdvancedFilters[name]
                                      : []
                                  }
                                  closePopup={() => {
                                    // checkboxClicked(id)
                                    unsetFilterSelectionConfigrationCheckedStatus();
                                  }}
                                />
                              </div>
                            );
                          }
                        }
                      )
                    : null}
                  {/* {
                                    filterSelectedToConfigure ? 
                                    <p>asdasd</p> : <p>asdasd</p>
                                    // [{...filterSelectedToConfigure}].map(({ id, name, checked, type }, index2) => {
                                    //     //console.log("ASDasdadasdas-----");
                                    //     if (type === 'multilist') {
                                    //         return (
                                    //             <div className="absolute top-3 left-0">
                                    //                 <MultiListSearchText
                                    //                     field_name={name}
                                    //                     id={props.id}
                                    //                     submitListValues={(list, field_name) => submitListValues(list, field_name)}
                                    //                     currentValues={(showAdvancedFilters[name] !== undefined) ? showAdvancedFilters[name] : []}
                                    //                     closePopup={() => {
                                    //                         // checkboxClicked(id)
                                    //                         unsetFilterSelectionConfigrationCheckedStatus()
                                    //                     }}
                                    //                 />
                                    //             </div>
                                    //         )
                                    //     }
                                    //     else if (type === "datetime") {
                                    //         return (
                                    //             <div className="absolute top-3 left-0">
                                    //                 <DateFilterSearch
                                    //                     field_name={name}
                                    //                     id={props.id}
                                    //                     submitListValues={(list, field_name) => submitListValues(list, field_name)}
                                    //                     currentValues={(showAdvancedFilters[name] !== undefined) ? showAdvancedFilters[name] : { from_date: '', to_date: '', from_time: '', to_time: '' }}
                                    //                     closePopup={() => {
                                    //                         unsetFilterSelectionConfigrationCheckedStatus()
                                    //                         // checkboxClicked(id)
                                    //                     }}
                                    //                 />
                                    //             </div>
                                    //         )
                                    //     }
                                    //     else if (type === "numeric") {
                                    //         return (
                                    //             // <h1>checked:{checked ? "true": "false"}&type:{type}</h1>
                                    //             <div className="absolute top-3 left-0">
                                    //                 <NumericFilterSearch
                                    //                     field_name={name}
                                    //                     id={props.id}
                                    //                     submitListValues={(list, field_name) => submitListValues(list, field_name)}
                                    //                     currentValues={(showAdvancedFilters[name] !== undefined) ? showAdvancedFilters[name] : {}}
                                    //                     closePopup={() => {
                                    //                         // checkboxClicked(id)
                                    //                         unsetFilterSelectionConfigrationCheckedStatus()
                                    //                     }}
                                    //                     getRangesValue={getRangesValue}
                                    //                 />
                                    //             </div>
                                    //         )
                                    //     }
                                    //     else if (type === "category") {
                                    //         return (
                                    //             <div className="absolute top-3 left-0">
                                    //                 <CatgoryFilterSearch
                                    //                     field_name={name}
                                    //                     id={props.id}
                                    //                     submitListValues={(list, field_name) => submitListValues(list, field_name)}
                                    //                     currentValues={(showAdvancedFilters[name] !== undefined) ? showAdvancedFilters[name] : {}}
                                    //                     closePopup={() => {
                                    //                         // checkboxClicked(id)
                                    //                         unsetFilterSelectionConfigrationCheckedStatus()
                                    //                     }}
                                    //                     filters={props.filters}
                                    //                 />
                                    //             </div>
                                    //         )
                                    //     }
                                    //     else if (type === "multichoicelist") {
                                    //         return (
                                    //             <div className="absolute top-3 left-0">
                                    //                 <MultiChoiceSearchText
                                    //                     field_name={name}
                                    //                     id={props.id}
                                    //                     submitListValues={(list, field_name) => submitListValues(list, field_name)}
                                    //                     currentValues={(showAdvancedFilters[name] !== undefined) ? showAdvancedFilters[name] : []}
                                    //                     closePopup={() => {
                                    //                         // checkboxClicked(id)
                                    //                         unsetFilterSelectionConfigrationCheckedStatus()
                                    //                     }}
                                    //                 />
                                    //             </div>
                                    //         )
                                    //     }
                                    // }): null
                                } */}
                </div>
              </div>
            );
          })}

        {filterSelectedToConfigure &&
          [{ ...filterSelectedToConfigure }].map((selection) => {
            return (
              <div>
                <div
                  id="new-added-filter"
                  className="relative left-0 mr-2 rounded border-2 px-3 py-2 font-poppins text-sm font-normal text-[#1B1B1D]"
                  onClick={() => {}}
                  style={{
                    borderColor: primaryColor ? primaryColor : "#6A1F70",
                    background:
                      seventhColor && seventhColor !== "#ffffff"
                        ? seventhColor
                        : "#FBEFFC",
                  }}
                >
                  {/* this is the CROSS / CANCEL for filters */}
                  <button
                    className="absolute -right-2 -top-2 rounded-full bg-white"
                    onClick={(e) => {
                      e.stopPropagation();
                      // delete the selection from the selected options
                      setFilterSelectedToConfigure(null);
                      // remove from the filters as well if present
                      // props.setFilters()
                      //    setIsRemoveSelectedOption(!isRemoveSelectedOption)
                    }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.5 7.56885C15.5 11.7111 12.1423 15.0688 8 15.0688C3.85775 15.0688 0.5 11.7111 0.5 7.56885C0.5 3.4266 3.85775 0.0688477 8 0.0688477C12.1423 0.0688477 15.5 3.4266 15.5 7.56885ZM5.7275 5.29635C5.83297 5.19101 5.97594 5.13184 6.125 5.13184C6.27406 5.13184 6.41703 5.19101 6.5225 5.29635L8 6.77385L9.4775 5.29635C9.58413 5.19699 9.72517 5.14289 9.87089 5.14547C10.0166 5.14804 10.1557 5.20707 10.2587 5.31013C10.3618 5.41319 10.4208 5.55223 10.4234 5.69796C10.426 5.84368 10.3719 5.98472 10.2725 6.09135L8.795 7.56885L10.2725 9.04635C10.3719 9.15298 10.426 9.29401 10.4234 9.43974C10.4208 9.58547 10.3618 9.7245 10.2587 9.82756C10.1557 9.93062 10.0166 9.98966 9.87089 9.99223C9.72517 9.9948 9.58413 9.94071 9.4775 9.84135L8 8.36385L6.5225 9.84135C6.41587 9.94071 6.27483 9.9948 6.12911 9.99223C5.98338 9.98966 5.84434 9.93062 5.74128 9.82756C5.63822 9.7245 5.57919 9.58547 5.57662 9.43974C5.57405 9.29401 5.62814 9.15298 5.7275 9.04635L7.205 7.56885L5.7275 6.09135C5.62216 5.98588 5.56299 5.84291 5.56299 5.69385C5.56299 5.54478 5.62216 5.40182 5.7275 5.29635Z"
                        fill="rgb(174,83,65)"
                      />
                    </svg>
                  </button>

                  {selection.label}
                </div>
                <div className="relative -left-2 z-40">
                  {filterSelectedToConfigure
                    ? filterSelectedToConfigure &&
                      [{ ...filterSelectedToConfigure }].map(
                        ({ id, name, checked, type, label }, index2) => {
                          if (type === "multilist") {
                            return (
                              <div className="absolute top-3 left-0">
                                <MultiListSearchText
                                  field_name={name}
                                  id={props.id}
                                  submitListValues={(list, field_name) => {
                                    setFilterSelections([
                                      ...filterSelections,
                                      {
                                        id,
                                        name,
                                        type,
                                        label,
                                      },
                                    ]);
                                    submitListValues(list, field_name);
                                    setFilterSelectedToConfigure(null);
                                  }}
                                  currentValues={
                                    showAdvancedFilters[name] !== undefined
                                      ? showAdvancedFilters[name]
                                      : []
                                  }
                                  closePopup={() => {
                                    setFilterSelectedToConfigure(null);
                                  }}
                                />
                              </div>
                            );
                          } else if (type === "datetime") {
                            return (
                              <div className="absolute top-3 left-0">
                                <DateFilterSearch
                                  field_name={name}
                                  id={props.id}
                                  submitListValues={(list, field_name) => {
                                    setFilterSelections([
                                      ...filterSelections,
                                      {
                                        id,
                                        name,
                                        type,
                                        label,
                                      },
                                    ]);
                                    submitListValues(list, field_name);
                                    setFilterSelectedToConfigure(null);
                                  }}
                                  currentValues={
                                    showAdvancedFilters[name] !== undefined
                                      ? showAdvancedFilters[name]
                                      : {
                                          from_date: "",
                                          to_date: "",
                                          from_time: "",
                                          to_time: "",
                                        }
                                  }
                                  closePopup={() => {
                                    setFilterSelectedToConfigure(null);
                                  }}
                                />
                              </div>
                            );
                          } else if (type === "numeric") {
                            return (
                              // <h1>checked:{checked ? "true": "false"}&type:{type}</h1>
                              <div className="absolute top-3 left-0">
                                <NumericFilterSearch
                                  field_name={name}
                                  id={props.id}
                                  submitListValues={async (
                                    list,
                                    field_name
                                  ) => {
                                    await setFilterSelections([
                                      ...filterSelections,
                                      {
                                        id,
                                        name,
                                        type,
                                        label,
                                      },
                                    ]);
                                    setFilterSelectedToConfigure(null);
                                    submitListValues(list, field_name);
                                  }}
                                  currentValues={
                                    showAdvancedFilters[name] !== undefined
                                      ? showAdvancedFilters[name]
                                      : {}
                                  }
                                  closePopup={() => {
                                    setFilterSelectedToConfigure(null);
                                  }}
                                  getRangesValue={getRangesValue}
                                />
                              </div>
                            );
                          } else if (type === "category") {
                            return (
                              <div className="absolute top-3 left-0">
                                <CatgoryFilterSearch
                                  field_name={name}
                                  id={props.id}
                                  submitListValues={(list, field_name) => {
                                    setFilterSelections([
                                      ...filterSelections,
                                      {
                                        id,
                                        name,
                                        type,
                                        label,
                                      },
                                    ]);
                                    submitListValues(list, field_name);
                                    setFilterSelectedToConfigure(null);
                                  }}
                                  currentValues={
                                    showAdvancedFilters[name] !== undefined
                                      ? showAdvancedFilters[name]
                                      : {}
                                  }
                                  closePopup={() => {
                                    setFilterSelectedToConfigure(null);
                                  }}
                                  filters={props.filters}
                                />
                              </div>
                            );
                          } else if (type === "multichoicelist") {
                            return (
                              <div className="absolute top-3 left-0">
                                <MultiChoiceSearchText
                                  field_name={name}
                                  id={props.id}
                                  submitListValues={(list, field_name) => {
                                    setFilterSelections([
                                      ...filterSelections,
                                      {
                                        id,
                                        name,
                                        type,
                                        label,
                                      },
                                    ]);
                                    submitListValues(list, field_name);
                                    setFilterSelectedToConfigure(null);
                                  }}
                                  currentValues={
                                    showAdvancedFilters[name] !== undefined
                                      ? showAdvancedFilters[name]
                                      : []
                                  }
                                  closePopup={() => {
                                    setFilterSelectedToConfigure(null);
                                  }}
                                />
                              </div>
                            );
                          }
                        }
                      )
                    : null}
                </div>
              </div>
            );
          })}
      </div>
      <div className="f-full flex gap-2">
        {advanceSelectedFiltersInput.length ? (
          advanceSelectedFiltersInput.map((input, index) => {
            return (
              <div key={index} className="flex items-center">
                {isRemoveSelectedOption ? (
                  <>
                    <div
                      className="relative left-0 mr-2 rounded border-2 border-[#6A1F70] bg-[#FBEFFC] px-3 py-2 font-poppins text-sm font-normal text-[#1B1B1D]"
                      onClick={() => {
                        setShowAdvanceFilterOptions(!showAdvanceFilterOptions);
                        // checkboxClicked(selectedOptionFromCheckList.id)
                      }}
                    >
                      <button
                        className="absolute -right-2 -top-2 bg-white"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsRemoveSelectedOption(!isRemoveSelectedOption);
                        }}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.5 7.56885C15.5 11.7111 12.1423 15.0688 8 15.0688C3.85775 15.0688 0.5 11.7111 0.5 7.56885C0.5 3.4266 3.85775 0.0688477 8 0.0688477C12.1423 0.0688477 15.5 3.4266 15.5 7.56885ZM5.7275 5.29635C5.83297 5.19101 5.97594 5.13184 6.125 5.13184C6.27406 5.13184 6.41703 5.19101 6.5225 5.29635L8 6.77385L9.4775 5.29635C9.58413 5.19699 9.72517 5.14289 9.87089 5.14547C10.0166 5.14804 10.1557 5.20707 10.2587 5.31013C10.3618 5.41319 10.4208 5.55223 10.4234 5.69796C10.426 5.84368 10.3719 5.98472 10.2725 6.09135L8.795 7.56885L10.2725 9.04635C10.3719 9.15298 10.426 9.29401 10.4234 9.43974C10.4208 9.58547 10.3618 9.7245 10.2587 9.82756C10.1557 9.93062 10.0166 9.98966 9.87089 9.99223C9.72517 9.9948 9.58413 9.94071 9.4775 9.84135L8 8.36385L6.5225 9.84135C6.41587 9.94071 6.27483 9.9948 6.12911 9.99223C5.98338 9.98966 5.84434 9.93062 5.74128 9.82756C5.63822 9.7245 5.57919 9.58547 5.57662 9.43974C5.57405 9.29401 5.62814 9.15298 5.7275 9.04635L7.205 7.56885L5.7275 6.09135C5.62216 5.98588 5.56299 5.84291 5.56299 5.69385C5.56299 5.54478 5.62216 5.40182 5.7275 5.29635Z"
                            fill="#228800"
                          />
                        </svg>
                      </button>
                      {selectedOptionFromCheckList.label} &nbsp; {input.label}{" "}
                      &nbsp;
                      {typeof input.value === "object"
                        ? `${input.value.from} to ${input.value.to}`
                        : input.value}
                    </div>
                    {advanceSelectedFiltersInput.length > 1 &&
                      index !== advanceSelectedFiltersInput.length - 1 &&
                      "or"}
                  </>
                ) : null}
              </div>
            );
          })
        ) : (
          <>
            {isRemoveSelectedOption ? (
              <div
                className="relative left-0 mr-2 rounded border-2 border-[#6A1F70] bg-[#FBEFFC] px-3 py-2 font-poppins text-sm font-normal text-[#1B1B1D]"
                onClick={() => {
                  setShowAdvanceFilterOptions(!showAdvanceFilterOptions);
                  // checkboxClicked(selectedOptionFromCheckList.id)
                }}
              >
                <button
                  className="absolute -right-2 -top-2 bg-white"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsRemoveSelectedOption(!isRemoveSelectedOption);
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.5 7.56885C15.5 11.7111 12.1423 15.0688 8 15.0688C3.85775 15.0688 0.5 11.7111 0.5 7.56885C0.5 3.4266 3.85775 0.0688477 8 0.0688477C12.1423 0.0688477 15.5 3.4266 15.5 7.56885ZM5.7275 5.29635C5.83297 5.19101 5.97594 5.13184 6.125 5.13184C6.27406 5.13184 6.41703 5.19101 6.5225 5.29635L8 6.77385L9.4775 5.29635C9.58413 5.19699 9.72517 5.14289 9.87089 5.14547C10.0166 5.14804 10.1557 5.20707 10.2587 5.31013C10.3618 5.41319 10.4208 5.55223 10.4234 5.69796C10.426 5.84368 10.3719 5.98472 10.2725 6.09135L8.795 7.56885L10.2725 9.04635C10.3719 9.15298 10.426 9.29401 10.4234 9.43974C10.4208 9.58547 10.3618 9.7245 10.2587 9.82756C10.1557 9.93062 10.0166 9.98966 9.87089 9.99223C9.72517 9.9948 9.58413 9.94071 9.4775 9.84135L8 8.36385L6.5225 9.84135C6.41587 9.94071 6.27483 9.9948 6.12911 9.99223C5.98338 9.98966 5.84434 9.93062 5.74128 9.82756C5.63822 9.7245 5.57919 9.58547 5.57662 9.43974C5.57405 9.29401 5.62814 9.15298 5.7275 9.04635L7.205 7.56885L5.7275 6.09135C5.62216 5.98588 5.56299 5.84291 5.56299 5.69385C5.56299 5.54478 5.62216 5.40182 5.7275 5.29635Z"
                      fill="#228800"
                    />
                  </svg>
                </button>
                {selectedOptionFromCheckList.label}
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default Filters;
