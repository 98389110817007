import { useEffect, useState } from "react";
import CheckTable from "views/admin/merchants/components/CheckTable";
import Cookies from "js-cookie";

const MerchantList = () => {
  const [tableList, setTableList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [columnsDataCheck, setColumnsDataCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAsending, setIsAsending] = useState(false);

  useEffect(() => {
    getAllMerchants({ page_no: page });
  }, []);

  async function sortFunction(field) {
    let asending = true;
    await setIsAsending((prevValue) => {
      asending = prevValue;
      return prevValue;
    });

    await setIsAsending(!asending);
  }

  function changePage(page_no) {}

  function getAllMerchants(object) {
    setIsLoading(true);
    var token = Cookies.get("token");
    fetch(`${process.env.REACT_APP_APIURL}/merchants_list`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(object),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        setColumnsDataCheck(true);
        setTableList(data[0]["record"]);
        setTotal(data[0]["total"]);
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  function confirmDelete(userid) {
    var token = Cookies.get("token");
    fetch(`${process.env.REACT_APP_APIURL}/delete_user`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ id: userid }),
    })
      .then((response) => response.json())
      .then((data) => {
        getAllMerchants({ page_no: page });
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  return (
    <div className="mt-0 grid grid-cols-1 gap-1">
      <div>
        <CheckTable
          columnsData={columnsDataCheck}
          tableData={tableList}
          setPage={(page) => {
            setPage(page);
            changePage(page);
          }}
          total={total}
          page={page}
          sortFunction={sortFunction}
          isLoading={isLoading}
          confirm_delete={(id) => confirmDelete(id)}
        />
      </div>
    </div>
  );
};

export default MerchantList;
