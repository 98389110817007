import { useEffect, useState } from "react";
import InputField from "components/fields/InputField";
import Dropdown from "react-dropdown";
import { useParams } from "react-router-dom";
import Radio from "components/radio";
import "react-dropdown/style.css";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import routes from "routes.js";
const UserProfile = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    merchant_id: "",
    role: "",
    userid: "",
    permissions: [],
  });

  const [defaultOption, setDefaultOption] = useState("");
  const [error, setError] = useState("");
  const [merchant, setMerchant] = useState([]);
  const { id } = useParams();
  const [modules, setModules] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState();
  const [role, setrole] = useState("");
  const [buttonLabel, setButtonLabel] = useState("Add User");

  useEffect(() => {
    __init();
  }, []);

  const __init = async () => {
    await setrole(localStorage.getItem("role"));
    await getAllMerchants();
    await getAllRoutes(routes);
    if (id !== undefined) {
      setButtonLabel("Update User");
      await getUserDetails(id);
    }
  };

  const getAllRoutes = (routes) => {
    let roles = [];
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].show_menu === true) {
        roles.push({ value: routes[i].route_info, label: routes[i].name });
      }
    }
    if (id !== "") {
      setFormData({ ...formData, permissions: roles });
    }
    setModules(roles);
  };

  const handleChange = (e) => {
    if (e.target.name === "role" && e.target.value === "merchant") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else if (e.target.name === "role" && e.target.value === "admin") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        merchant_id: "",
      });
      setSelectedMerchant([]);
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  async function createUser(details) {
    return fetch(`${process.env.REACT_APP_APIURL}/create_user`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    }).then((data) => data.json());
  }

  async function getUserDetails(userid) {
    return fetch(`${process.env.REACT_APP_APIURL}/get_user`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: userid }),
    })
      .then((response) => response.json())
      .then((data) => {
        var role = localStorage.getItem("role");
        if (role === "merchant" && data["role"] !== "user") {
          window.location.href = "/admin/user_list";
        } else {
          //   setFormData({ ...data, userid: userid });
          setSelectedMerchant(data["merchant_id"]);
          handleMerchantChange(data["merchant_id"], {
            ...data,
            userid: userid,
          });
        }
      });
  }

  function getAllMerchants() {
    fetch(`${process.env.REACT_APP_APIURL}/merchants_list`, {
      method: "post",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        const uniqueArray = data[0].record;
        var list = [];
        uniqueArray.map((item, index) => {
          list.push({ value: item.id, label: item.name });
        });
        setMerchant(list);
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  const handleSubmit = async (e) => {
    setError("");
    e.preventDefault();
    const token = await createUser(formData);
    if (
      token !== undefined &&
      token !== "" &&
      token !== null &&
      token.length !== 0 &&
      token[0]["status"] === "success"
    ) {
      window.location.href = "/admin/user_list";
    } else {
      setError(token[0]["error"]);
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            className="multiple_option mr-2"
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleOptionChange = (selected) => {
    setFormData({ ...formData, permissions: selected });
  };

  const handleMerchantChange = (selected, obj = "") => {
    var selected_merch = [];
    for (var i = 0; i < selected.length; i++) {
      selected_merch.push(selected[i]["value"]);
    }
    setSelectedMerchant(selected);
    if (obj !== "") {
      setFormData({ ...obj, merchant_id: selected_merch });
    } else {
      setFormData({ ...formData, merchant_id: selected_merch });
    }
  };

  return (
    <div className="mt-2 mb-16 flex h-full w-full justify-center px-2 md:mx-0 md:px-0 lg:mb-10">
      {/* Sign in section */}
      <div className="mt-[5vh] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
        {error !== "" && (
          <div className="mx-2 ml-1 text-base text-red-500">{error}</div>
        )}
        {/* Email */}
        <form onSubmit={handleSubmit}>
          <InputField
            variant="auth"
            extra="mb-3"
            label="First name*"
            placeholder="First name"
            name="firstname"
            id="firstname"
            type="text"
            value={formData.firstname}
            onChange={(e) => handleChange(e)}
          />

          <InputField
            variant="auth"
            extra="mb-3"
            label="Last name*"
            placeholder="Last name"
            name="lastname"
            id="lastname"
            type="text"
            value={formData.lastname}
            onChange={(e) => handleChange(e)}
          />

          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="Email"
            id="email"
            name="email"
            type="email"
            value={formData.email}
            onChange={(e) => handleChange(e)}
          />
          <div className="align-center my-8 flex">
            {role === "admin" && (
              <label for="adminrole" className="flex gap-2">
                <Radio
                  name="role"
                  id="adminrole"
                  value="admin"
                  checked={formData.role === "admin"}
                  onClick={(e) => handleChange(e)}
                />{" "}
                Super Admin
              </label>
            )}
            <label for="merchantrole" className=" ml-8 flex gap-2">
              <Radio
                name="role"
                id="merchantrole"
                value="merchant"
                checked={formData.role === "merchant"}
                onClick={(e) => {
                  handleChange(e);
                }}
              />{" "}
              Cusomer Admin
            </label>
            <label for="userrole" className=" ml-8 flex gap-2">
              <Radio
                name="role"
                id="userrole"
                value="user"
                checked={formData.role === "user"}
                onClick={(e) => handleChange(e)}
              />{" "}
              User
            </label>
          </div>
          <div className="my-8">
            <label className="py-4">
              {" "}
              Merchant
              <ReactSelect
                options={merchant}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                isDisabled={formData.role === "admin" ? true : false}
                components={{
                  Option,
                }}
                onChange={(value) => handleMerchantChange(value)}
                allowSelectAll={true}
                value={selectedMerchant}
                placeholder="All"
              />
            </label>
          </div>

          {modules.length > 0 &&
            (formData.role === "user" || formData.role === "merchant") && (
              <div className="my-8">
                <label className="py-4">
                  {" "}
                  Metric Permissions
                  <ReactSelect
                    options={modules}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    isDisabled={formData.role === "merchant" ? true : false}
                    onChange={(value) => handleOptionChange(value)}
                    allowSelectAll={true}
                    value={formData.permissions}
                    placeholder="All"
                  />
                </label>
              </div>
            )}

          <button
            type="submit"
            className="linear m-background mt-2 w-full rounded-xl bg-green-900 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-700 active:bg-green-700 dark:bg-green-900 dark:text-white dark:hover:bg-green-700 dark:active:bg-green-700"
          >
            {buttonLabel}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserProfile;
