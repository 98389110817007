import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import ColumnManagementModal from "./ColumnManagementModal";

const CheckTable = (props) => {
  const {
    columnsData,
    tableData,
    columnsToShow,
    totalRowsToDisplay,
    fetchMoreData,
    hasMoreDataToLoad,
    merchantColors,
    page,
    selectedTimezone,
    isSelectedMerchant,
  } = props;

  const [isColumnModalOpen, setIsColumnModalOpen] = useState(false);
  const [availableColumns, setAvailableColumns] = useState(columnsData);
  const [visibleColumns, setVisibleColumns] = useState(columnsToShow);

  const handleOpenColumnModal = () => {
    setIsColumnModalOpen(true);
  };

  const handleCloseColumnModal = () => {
    setIsColumnModalOpen(false);
  };

  const handleSaveColumnChanges = (newVisibleColumns, newAvailableColumns) => {
    setVisibleColumns(newVisibleColumns);
    setAvailableColumns(newAvailableColumns);
    props.onColumnUpdate?.(newVisibleColumns);
    handleCloseColumnModal();
  };

  function replaceWords(text) {
    if (text !== undefined) {
      return text
        .replace(/scheduled/g, "sch")
        .replace(/requested/g, "req")
        .replace(/clicked/g, "clk")
        .replace(/submitted/g, "sub")
        .replace(/UserUnresponsive/g, "")
        .replace(/Callback/g, "CallBk")
        .replace(/Meeting/g, "Mtg");
    } else {
      return text;
    }
  }

  // Determine header background color
  const headerBackgroundColor = merchantColors?.primaryColor
    ? merchantColors.primaryColor
    : !isSelectedMerchant
    ? "#6a1f70"
    : "#FFFFFF";

  // Determine header text color
  const headerTextColor =
    isSelectedMerchant && !merchantColors?.primaryColor ? "#6a1f70" : "#ffffff";

  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleOpenColumnModal}
        sx={{
          mb: 2,
          backgroundColor: headerBackgroundColor,
          color: headerTextColor,
          "&:hover": {
            backgroundColor: headerBackgroundColor,
            opacity: 0.9,
          },
        }}
      >
        Manage Columns
      </Button>

      <ColumnManagementModal
        open={isColumnModalOpen}
        onClose={handleCloseColumnModal}
        availableColumns={availableColumns}
        visibleColumns={visibleColumns}
        onSave={handleSaveColumnChanges}
      />

      <Paper>
        <InfiniteScroll
          dataLength={page * 25}
          next={() => {
            if (props.total > page * 25) {
              fetchMoreData();
            }
          }}
          hasMore={true}
          scrollableTarget="scrollableDiv"
          height={600}
          endMessage={<p>No more data to load.</p>}
          useWindow={false}
        >
          <TableContainer sx={{ overflowX: "visible" }}>
            <Table sx={{ minWidth: 800 }} stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: headerBackgroundColor,
                      color: headerTextColor,
                      fontWeight: "bold",
                      zIndex: 1,
                      whiteSpace: "nowrap",
                      padding: "8px 16px",
                      height: "32px",
                    }}
                  >
                    No.
                  </TableCell>
                  <TableCell
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: headerBackgroundColor,
                      color: headerTextColor,
                      fontWeight: "bold",
                      zIndex: 1,
                      whiteSpace: "nowrap",
                      padding: "8px 16px",
                      height: "32px",
                    }}
                  >
                    Conversation #
                  </TableCell>
                  {visibleColumns.map((column) => (
                    <TableCell
                      key={column.value}
                      sx={{
                        position: "sticky",
                        top: 0,
                        backgroundColor: headerBackgroundColor,
                        color: headerTextColor,
                        fontWeight: "bold",
                        zIndex: 1,
                        whiteSpace: "nowrap",
                        padding: "8px 16px",
                        height: "32px",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "white" : "#f5f5f5",
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                  >
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        padding: "4px 16px",
                        height: "32px",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        padding: "4px 16px",
                        height: "32px",
                      }}
                    >
                      <a
                        href={`/chat/conversation?cid=${row.conversation_id}&mid=${row.merchant_id}`}
                        target="_blank"
                      >
                        {row.conversation_id.slice(-6)}
                      </a>
                    </TableCell>
                    {visibleColumns.map((column) => (
                      <TableCell
                        key={column.value}
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          padding: "4px 16px",
                          height: "32px",
                        }}
                      >
                        {column.value === "conv_outcome"
                          ? replaceWords(row[column.value])
                          : row[column.value]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </Paper>
    </Box>
  );
};

export default CheckTable;
