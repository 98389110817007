/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import Dropdown from "react-dropdown";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import ReactSelect from "react-select";
import { components } from "react-select";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerConfig, setFilters } from "../../../store/actions";
import { timezones } from "./timezoneValue";
import toGetColorCode from "hooks/useToGetColorCode";
import { getUserConfig } from "utils/utils";

import "./Links.css";
import { setDefaultLocale } from "react-datepicker";

// chakra imports
export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();

  const { routes, updateFilterValue } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
    const [merchants, setMerchants] = useState([]);
    const [defaultMerchant, setDefaultMerchant] = useState();
    const filters = useSelector((state) => state?.filters);
    const selectedMerchant = useSelector((state) => state.selectedValue);
    const loadingList = useSelector((state) => state.loadingList);
    const selectedTimezone = useSelector((state) => state.selectedTimeZone);
    const customerConfig = useSelector((state) => state.customerConfig);
    const [timezone, setTimezone] = useState();
    const primaryColor =
      customerConfig.configuration &&
      customerConfig.configuration["primary_color"];
    const secondaryColor = toGetColorCode(primaryColor, 3, "white");
    const tertiaryColor = toGetColorCode(primaryColor, 5, "white");
    const seventhColor = toGetColorCode(primaryColor, 7, "white");

    const [requiredColorForPage, setRequiredColorForPage] = useState({
      primaryColor: primaryColor,
      secondaryColor: secondaryColor,
      tertiaryColor: tertiaryColor,
      seventhColor: seventhColor,
    });

    const sidebarRef = useRef(null);

    const dispatch = useDispatch();

    let role = localStorage.getItem("role");
    let permission = localStorage.getItem("permission");
    if (role === undefined) {
      role = "";
    }
    var permissions = [];
    if (permission !== undefined) {
      var permission_list = JSON.parse(permission);
      if (permission_list !== null) {
        for (var i = 0; i < permission_list.length; i++) {
          permissions.push(permission_list[i]["value"]);
        }
      }
    }

    const merchantOptions = async () => {
      var token = Cookies.get("token");
      fetch(`${process.env.REACT_APP_APIURL}/merchants_list`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const uniqueArray = data[0]["record"];
          var list = [];
          uniqueArray.map((item, index) => {
            list.push({ value: item.name, label: item.name });
          });

          if (uniqueArray.length === 1) {
            dispatch(getCustomerConfig(uniqueArray[0].name));
            dispatch({
              type: "SET_SELECTED_VALUE",
              payload: [uniqueArray[0].name],
            });
          }

          setMerchants(list);
        })
        .catch((error) => {
          //console.error(error);
        });
    };

    const handleChangeMerchant = async (selected) => {
      // props.setFilters({ merchant: selected })
      await dispatch({ type: "SET_LOADING_LIST", payload: true });
      let array = [];
      await dispatch(setFilters({ landingpage: [] }));
      selected.forEach((item, index) => {
        array.push(item["value"]);
      });
      if (array.length === 1) {
        await dispatch(getCustomerConfig(array[0]));
        await dispatch({ type: "SET_SELECTED_VALUE", payload: [array[0]] });
      } else if (array.length > 1) {
        await dispatch(getCustomerConfig("All"));
        await dispatch({ type: "SET_SELECTED_VALUE", payload: array });
      } else {
        await dispatch(getCustomerConfig("All"));
        await dispatch({ type: "SET_SELECTED_VALUE", payload: "All" });
      }
      await setDefaultMerchant(selected);
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const handleChangeTimezone = (selectedTimezone) => {
      if (selectedTimezone) {
        setTimezone(selectedTimezone);
        dispatch(
          setFilters({
            timezone: selectedTimezone.value,
          })
        );
        dispatch({
          type: "SET_SELECTED_TIMEZONE",
          payload: selectedTimezone.value,
        });
      }
      sidebarRef.current.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
      merchantOptions();
      setdefaultTimezone();
      setMerchantValue();
    }, []);

    async function setdefaultTimezone() {
      const _default = await timezones.forEach((item, index) => {
        if (item.value === filters.timezone) {
          setTimezone({ value: item.value, label: item.label });
        }
      });
    }

    async function setMerchantValue() {
      if (filters.merchant.length > 0) {
        var merchants = [];
        filters.merchant[0].value.forEach((item, index) => {
          merchants.push({ label: item, value: item });
        });
        setDefaultMerchant(merchants);
      }
    }

    function updateFilterValue(obj) {
      // dispatch(setFilters(obj))
      // setFilterChange(true)
      // if (obj.reporttype) {
      //   setSelectedTab(obj.reporttype)
      // }
    }

    async function getMerchantConfiguration(merchant_value) {
      const config_values = await getUserConfig(merchant_value);

      if (config_values !== null && config_values !== undefined) {
        const primaryColor = config_values["primary_color"];
        const secondaryColor = toGetColorCode(primaryColor, 3, "white");
        const tertiaryColor = toGetColorCode(primaryColor, 5, "white");
        const seventhColor = toGetColorCode(primaryColor, 7, "white");

        await setRequiredColorForPage({
          ...requiredColorForPage,
          primaryColor: primaryColor,
          secondaryColor: secondaryColor,
          seventhColor: seventhColor,
          tertiaryColor: tertiaryColor,
        });
      }

      if (merchant_value.length == 0) {
        await setRequiredColorForPage({
          ...requiredColorForPage,
          primaryColor: "",
          secondaryColor: "",
          seventhColor: "",
          tertiaryColor: "",
        });
      }
    }

    useEffect(() => {
      if (selectedMerchant !== "All") {
        setRequiredColorForPage({ ...requiredColorForPage });
        // getMerchantConfiguration(customerConfig.configuration, selectedMerchant)
        // getMerchantConfiguration([])

        //console.log("Selected Merchant", selectedMerchant)
        if (selectedMerchant === "") {
          updateFilterValue({
            merchant: "",
          });
        } else {
          updateFilterValue({
            merchant: [{ label: selectedMerchant, value: selectedMerchant }],
          });
        }

        const primaryColor =
          customerConfig.configuration &&
          customerConfig.configuration["primary_color"];
        const secondaryColor = toGetColorCode(primaryColor, 3, "white");
        const tertiaryColor = toGetColorCode(primaryColor, 5, "white");
        const seventhColor = toGetColorCode(primaryColor, 7, "white");

        setRequiredColorForPage({
          primaryColor: primaryColor,
          secondaryColor: secondaryColor,
          seventhColor: seventhColor,
          tertiaryColor: tertiaryColor,
        });
      } else {
        // getMerchantConfiguration({}, [])
        setRequiredColorForPage({
          ...requiredColorForPage,
          primaryColor: "",
          secondaryColor: "",
          seventhColor: "",
          tertiaryColor: "",
        });
        updateFilterValue({
          merchant: "",
        });
      }
    }, [selectedMerchant, customerConfig.configuration]);

    const customStyles = {
      option: (baseStyles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...baseStyles,
          backgroundColor:
            (isSelected || isFocused) && requiredColorForPage.primaryColor
              ? requiredColorForPage.primaryColor
              : "transparent",
          color:
            (isSelected || isFocused) && requiredColorForPage.primaryColor
              ? "#FFFFFF"
              : "#000000",
        };
      },
      control: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: "#3b3b3b",
        border: "none",
        // borderColor: state.isFocused ? 'red' : 'blue',
        color: "white",
      }),
      placeholder: (baseStyles) => {
        return {
          ...baseStyles,
          color: "#ffffff",
        };
      },
    };

    return (
      <div ref={sidebarRef} style={{}} className="border-[red] pt-4">
        {routes.map((route, index) => {
          if (
            (route.layout === "/admin" ||
              route.layout === "/auth" ||
              route.layout === "/rtl") &&
            route.skip === false &&
            (route.roles.length === 0 ||
              route.roles.indexOf(role) > -1 ||
              (permissions !== null &&
                permissions.length > 0 &&
                permissions.indexOf(route.route_info) > -1))
          ) {
            return (
              <>
                <Link key={index} to={route.layout + "/" + route.path}>
                  <div className="relative mb-3 flex hover:cursor-pointer">
                    <li
                      className={`flex w-full cursor-pointer items-center py-[5px] px-6 ${
                        activeRoute(route.path) === true
                          ? "m-background bg-green-900"
                          : ""
                      }`}
                      key={index}
                    >
                      <span className="font-poppins text-sm font-medium text-white"></span>
                      <p
                        className={`leading-1 flex font-poppins text-sm font-medium text-white`}
                      >
                        {route.name}
                      </p>
                    </li>
                  </div>
                </Link>
              </>
            );
          }
        })}

        <Link
        // to={route.layout + "/" + route.path}
        >
          <div className="relative mb-3 flex hover:cursor-pointer">
            <li
              className={`flex w-full cursor-pointer items-center py-[0px] px-3`}
            >
              <p
                className={`leading-1 timezoneSelectCustomStyle flex w-full font-poppins text-sm font-medium`}
              >
                {/* <Dropdown
                options = {[...timezones.map((timezone) => timezone.label)]}
                value={timezones.find((timezone) => timezone.value == filters.timezone)?.label}
               
                onChange={(e) => { 
                  const value = e.value;
                  const timezone = timezones.find((timezone) => timezone.label == value);
                  if (timezone) {
                    dispatch(setFilters({
                      timezone: timezone.value,
                    }));
                  }
                  // updateFilterValue({ timezone: e.value }) 
                }}
                placeholder="Timezone"
                className="w-full"
               
              /> */}

                <ReactSelect
                  options={timezones}
                  // isMulti
                  // closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    DropdownIndicator,
                  }}
                  onChange={(value) => handleChangeTimezone(value)}
                  onMenuOpen={() =>
                    sidebarRef.current.scrollIntoView({
                      behavior: "smooth",
                      block: "end",
                      inline: "nearest",
                    })
                  }
                  // allowSelectAll={true}
                  // value={filters.timezone}
                  value={timezone}
                  placeholder="Timezone"
                  className="w-full"
                  styles={customStyles}
                />
              </p>
            </li>
          </div>
        </Link>

        <Link
        // to={route.layout + "/" + route.path}
        >
          <div className="relative mb-3 flex hover:cursor-pointer">
            <li
              className={`flex w-full cursor-pointer items-center py-[0px] px-3`}
            >
              <p
                className={`leading-1 flex w-full font-poppins text-sm font-medium`}
              >
                {merchants.length > 1 && (
                  <ReactSelect
                    //isDisabled={loadingList}
                    options={merchants}
                    isMulti
                    // closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                      DropdownIndicator,
                    }}
                    onChange={(value) => handleChangeMerchant(value)}
                    onMenuOpen={() =>
                      sidebarRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                        inline: "nearest",
                      })
                    }
                    allowSelectAll={true}
                    value={defaultMerchant}
                    placeholder="All Customer"
                    className="w-full"
                    styles={customStyles}
                  />
                )}
              </p>
            </li>
          </div>
        </Link>
      </div>
    );
  };
  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;

function Option(props) {
  return (
    <div>
      <components.Option {...props}>
        <div className="flex flex-row justify-between">
          <label>{props.label}</label>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            className="multiple_option mr-2"
          />{" "}
        </div>
      </components.Option>
    </div>
  );
}

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <span style={{ fontSize: "18px", color: "rgb(153, 153, 153)" }}>
        &#x25BE;
      </span>
    </components.DropdownIndicator>
  );
}
