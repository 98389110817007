import { useEffect, useState } from "react";
import InputField from "components/fields/InputField";
import Dropdown from "react-dropdown";
import { useParams } from "react-router-dom";
import Radio from "components/radio";
import "react-dropdown/style.css";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import routes from "routes.js";
import Cookies from "js-cookie";

const Assignlp = () => {
  const [formData, setFormData] = useState({
    userid: "",
    landingPage: [],
  });

  const [defaultOption, setDefaultOption] = useState("");
  const [error, setError] = useState("");
  const { id } = useParams();
  const [lp, setLp] = useState([]);
  const [buttonLabel, setButtonLabel] = useState("Assign Landing Pages");

  useEffect(() => {
    __init();
    if (id !== undefined) {
      getUserDetails(id);
    }
  }, []);

  async function getUserDetails(userid) {
    var token = Cookies.get("token");
    return fetch(`${process.env.REACT_APP_APIURL}/get_user`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ id: userid }),
    })
      .then((response) => response.json())
      .then((data) => {
        var list = [];
        var d = data["landing_pages"];
        if (Object.keys(d).length === 0) {
          setFormData({ landingPage: [], userid: userid });
          return;
        }
        d.map((item, index) => {
          if (item !== "") {
            list.push({ value: item, label: item });
          }
        });

        setFormData({ landingPage: list, userid: userid });
      });
  }

  const __init = async () => {
    await getMerchantLandingPages(id);
  };

  function getMerchantLandingPages(userid) {
    var token = Cookies.get("token");
    fetch(`${process.env.REACT_APP_APIURL}/landingpage_list`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ id: userid }),
    })
      .then((response) => response.json())
      .then((data) => {
        const uniqueArray = Array.from(new Set(data[0].record));

        var list = [];
        uniqueArray.map((item, index) => {
          if (item !== "") {
            list.push({ value: item, label: item });
          }
        });
        setLp(list);
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            className="multiple_option mr-2"
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleSubmit = async (e) => {
    setError("");
    e.preventDefault();
    let lp = [];
    formData.landingPage.forEach((row, index) => {
      lp.push(row.value);
    });
    const status = await updateUser({
      landingPage: lp,
      userid: formData.userid,
    });
    if (status[0]["status"] === "success") {
      window.location.href = "/admin/user_list";
    } else {
      setError(status[0]["error"]);
    }
  };

  async function updateUser(details) {
    var token = Cookies.get("token");
    return fetch(`${process.env.REACT_APP_APIURL}/update_user_landingpages`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(details),
    }).then((data) => data.json());
  }

  const handleOptionChange = (selected) => {
    setFormData({ ...formData, landingPage: selected });
  };

  return (
    <div className="mt-2 mb-16 flex h-full w-full justify-center px-2 md:mx-0 md:px-0 lg:mb-10">
      {/* Sign in section */}
      <div className="mt-[5vh] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
        {error !== "" && (
          <div className="mx-2 ml-1 text-base text-red-500">{error}</div>
        )}
        {/* Email */}
        <form onSubmit={handleSubmit}>
          <div className="my-8">
            <label className="py-4">
              {" "}
              Landing Pages
              <ReactSelect
                options={lp}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option,
                }}
                isDisabled={lp.length === 0 ? true : false}
                onChange={(value) => handleOptionChange(value)}
                allowSelectAll={true}
                value={formData.landingPage}
                placeholder="All"
              />
            </label>
          </div>

          <button
            type="submit"
            className="linear m-background mt-2 w-full rounded-xl bg-green-900 py-[12px] text-base font-medium text-white transition duration-200 dark:bg-green-900 dark:text-white dark:hover:bg-green-700 dark:active:bg-green-700"
          >
            {buttonLabel}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Assignlp;
