import { useEffect, useState } from "react";
import InputField from "components/fields/InputField";
import Dropdown from "react-dropdown";
import { useParams } from "react-router-dom";
import Radio from "components/radio";
import "react-dropdown/style.css";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import routes from "routes.js";
import Cookies from "js-cookie";

var tmpPermission = [];

const UserAccount = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    merchant_id: "",
    role: "",
    userid: "",
    permissions: [],
  });

  const [defaultOption, setDefaultOption] = useState("");
  const [error, setError] = useState("");
  const [merchant, setMerchant] = useState([]);
  const { id } = useParams();
  const [modules, setModules] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState();
  const [role, setrole] = useState("");
  const [buttonLabel, setButtonLabel] = useState("Add User");
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    __init();
  }, []);

  const __init = async () => {
    await setrole(localStorage.getItem("role"));
    await getAllMerchants();
    await getAllRoutes(routes);
    if (id !== undefined) {
      setButtonLabel("Update User");
      await getUserDetails(id);
    }
  };

  const getAllRoutes = (routes) => {
    let roles = [];
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].show_menu === true) {
        roles.push({ value: routes[i].route_info, label: routes[i].name });
      }
    }
    if (id !== "") {
      setFormData({ ...formData, permissions: roles });
      tmpPermission = roles;
    }
    setModules(roles);
  };

  const handleChange = (e) => {
    if (e.target.name === "role" && e.target.value === "merchant") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        permissions: modules,
      });
    } else if (e.target.name === "role" && e.target.value === "admin") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        merchant_id: "",
      });
      setSelectedMerchant([]);
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        permissions: tmpPermission,
      });
    }
  };

  async function createUser(details) {
    var token = Cookies.get("token");
    return fetch(`${process.env.REACT_APP_APIURL}/create_user`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(details),
    }).then((data) => data.json());
  }

  async function getUserDetails(userid) {
    var token = Cookies.get("token");
    return fetch(`${process.env.REACT_APP_APIURL}/get_user`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ id: userid }),
    })
      .then((response) => response.json())
      .then((data) => {
        //setFormData({ ...data, userid: userid });
        setSelectedMerchant(data["merchant_id"]);
        tmpPermission = data.permissions;
        handleMerchantChange(data["merchant_id"], { ...data, userid: userid });
      });
  }

  function getAllMerchants() {
    var token = Cookies.get("token");
    fetch(`${process.env.REACT_APP_APIURL}/merchants_list`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const uniqueArray = data[0].record;
        var list = [];
        uniqueArray.map((item, index) => {
          list.push({ value: item.id, label: item.name });
        });
        //console.log(uniqueArray[0].name)

        if (uniqueArray.length === 1 && id === undefined) {
          setFormData({ ...formData, merchant_id: [uniqueArray[0].id] });
        }
        setMerchant(list);
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  const handleSubmit = async (e) => {
    setIsDisabled(true);
    setError("");
    e.preventDefault();
    const isError = await validate_details(formData);
    if (isError === "") {
      const token = await createUser(formData);
      if (
        token !== undefined &&
        token !== "" &&
        token !== null &&
        token.length !== 0 &&
        token[0]["status"] === "success"
      ) {
        window.location.href = "/admin/user_list";
      } else {
        setError(token[0]["error"]);
        setIsDisabled(false);
      }
    } else {
      setError(isError);
      setIsDisabled(false);
    }
  };

  function validate_details(details) {
    if (
      details["firstname"] === "" ||
      details["lastname"] === "" ||
      details["role"] === "" ||
      details["email"] === ""
    ) {
      return "Please fill all requred fields";
    }
    if (
      details["role"] !== "admin" &&
      (details["merchant_id"] === "" || details["merchant_id"].length === 0)
    ) {
      return "Please select atleast one merchant id for the selected role";
    }
    //console.log(details)
    return "";
  }

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            className="multiple_option mr-2"
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleOptionChange = (selected) => {
    setFormData({ ...formData, permissions: selected });
  };

  const handleMerchantChange = (selected, obj = "") => {
    var selected_merch = [];
    for (var i = 0; i < selected.length; i++) {
      selected_merch.push(selected[i]["value"]);
    }
    setSelectedMerchant(selected);
    if (obj !== "") {
      setFormData({ ...obj, merchant_id: selected_merch });
    } else {
      setFormData({ ...formData, merchant_id: selected_merch });
    }
  };

  return (
    <div className="mt-2 mb-16 flex h-full w-full justify-center px-2 md:mx-0 md:px-0 lg:mb-10">
      {/* Sign in section */}
      <div className="mt-[5vh] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
        {error !== "" && (
          <div className="mx-2 ml-1 text-base text-red-500">{error}</div>
        )}
        {/* Email */}
        <form onSubmit={handleSubmit}>
          <InputField
            variant="auth"
            extra="mb-3"
            label="First name*"
            placeholder="First name"
            name="firstname"
            id="firstname"
            type="text"
            value={formData.firstname}
            onChange={(e) => handleChange(e)}
          />

          <InputField
            variant="auth"
            extra="mb-3"
            label="Last name*"
            placeholder="Last name"
            name="lastname"
            id="lastname"
            type="text"
            value={formData.lastname}
            onChange={(e) => handleChange(e)}
          />

          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="Email"
            id="email"
            name="email"
            type="email"
            value={formData.email}
            onChange={(e) => handleChange(e)}
          />
          <div className="align-center my-8 flex">
            {role === "admin" && (
              <label for="adminrole" className="flex gap-2">
                <Radio
                  name="role"
                  id="adminrole"
                  value="admin"
                  checked={formData.role === "admin"}
                  onClick={(e) => handleChange(e)}
                />{" "}
                Super Admin
              </label>
            )}
            <label for="merchantrole" className=" ml-8 flex gap-2">
              <Radio
                name="role"
                id="merchantrole"
                value="merchant"
                checked={formData.role === "merchant"}
                onClick={(e) => {
                  handleChange(e);
                }}
              />{" "}
              Customer Admin
            </label>
            <label for="userrole" className=" ml-8 flex gap-2">
              <Radio
                name="role"
                id="userrole"
                value="user"
                checked={formData.role === "user"}
                onClick={(e) => handleChange(e)}
              />{" "}
              User
            </label>
          </div>
          {merchant.length > 1 && (
            <div className="my-8">
              <label className="py-4">
                {" "}
                Customer
                <ReactSelect
                  options={merchant}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  isDisabled={formData.role === "admin" ? true : false}
                  components={{
                    Option,
                  }}
                  onChange={(value) => handleMerchantChange(value)}
                  allowSelectAll={true}
                  value={selectedMerchant}
                  placeholder={
                    formData.role === "admin" ? "All" : "Select Customer"
                  }
                />
              </label>
            </div>
          )}

          {modules.length > 0 &&
            (formData.role === "user" || formData.role === "merchant") && (
              <div className="my-8">
                <label className="py-4">
                  {" "}
                  Metric Permissions
                  <ReactSelect
                    options={modules}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    isDisabled={formData.role === "merchant" ? true : false}
                    onChange={(value) => handleOptionChange(value)}
                    allowSelectAll={true}
                    value={formData.permissions}
                    placeholder="All"
                  />
                </label>
              </div>
            )}

          <button
            type="submit"
            className="linear m-background mt-2 w-full rounded-xl bg-green-900 py-[12px] text-base font-medium text-white transition duration-200 disabled:opacity-50 dark:bg-green-900 dark:text-white dark:active:bg-green-700"
            disabled={isDisabled}
          >
            {buttonLabel}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserAccount;
